import {info_page} from './navigation.js';

export function generalClickHandler(e) {
  if (e.target.tagName.toLowerCase() == 'a') {
    const href = e.target.getAttribute('href');
    if (href.indexOf('popup') == 0) {
      const targetPopUp = href.substr(6).split('#');
      info_page(targetPopUp[0], targetPopUp[1] ? targetPopUp[1] : null);
      e.preventDefault();
    }
  }
}
