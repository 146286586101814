import './popup.css';
import Draggable from 'draggable';
import config from '../data/config.json';
import markup from './popup.thtml';
import {bodenkarte, isInAustria, rasterkarte} from './maplayers.js';
import {bofoBeautify, profilstelleBeautify} from './databeautify.js';
import {hideunwantedstuff} from './searchbar.js';
import {highlight, unhighlight} from './style.js';
import {layerNames, layers} from './layers.js';
import {legendenHash} from './legend.js';

// popup und overlay als ziel...
export const popuptarget = document.createElement('div');
popuptarget.classList.add('popup');

/**
 * Click listener on the popup's closer.
 * @param {MouseEvent} event Click event
 */
function close(event) {
  if (event.target.classList.contains('closethis')) {
    popdown();
  }
}

/* extracts feature depending on layer situation */
function extractFeature(map, pixel, pfilter) {
  const data = map.forEachFeatureAtPixel(pixel, function (feature, layer) {
    if (!layer) {
      return;
    }
    switch (pfilter) {
      case 'erscheinung_jahr.bofo_id':
        if (layer.get('name') == 'kartierungsbereich_mpoly') {
          return {'feature': feature, 'layer': layer};
        }
        break;
      default:
        if (layer.get('name') != 'kartierungsbereich_mpoly') {
          return {'feature': feature, 'layer': layer};
        }
        break;
    }
  });
  if (!data && pfilter !== 'erscheinung_jahr.bofo_id') {
    const value = rasterkarte.getData(pixel)?.[0];
    if (value) {
      return {value, 'layer': rasterkarte};
    }
  }
  return data;
}

/**
 * Displays a popup at the clicked position.
 * @param {module:ol/Map~Map} map Map to add the popup to.
 * Feature that the popup content is generated for.
 * @param {module:ol/coordinate~Coordinate} e event for the popup.
 */
export function popup(map, e) {
  const pfilter =
    layers.kartierungsbereich_mpoly.type + '.' + layers.bodenform_mpoly.type;
  const featureandlayer = extractFeature(map, e.pixel, pfilter);
  if (!featureandlayer) {
    return;
  }
  // don't make popups when clicking on feature text
  if (
    featureandlayer.layer &&
    (featureandlayer.layer.get('name') === 'sketch' ||
      featureandlayer.layer.get('name') === 'importedGeoJSON')
  ) {
    return;
  }

  let headerstring = '';
  let contentstring = '';
  let odimensions = {'width': 300, 'height': 90};

  if (featureandlayer.value) {
    const legend = legendenHash[layers.erosionskarte.type];
    const contentstring =
      '<span class="coloredbox" style="opacity: ' +
      rasterkarte.getOpacity() +
      '; background-color: ' +
      legend[featureandlayer.value].color +
      ';"></span>' +
      legend[featureandlayer.value].legende;
    popitup(
      config.topics[layers.erosionskarte.type].title,
      contentstring,
      odimensions,
      map,
      e.coordinate,
      e.pixel
    );
    return;
  }

  highlight(featureandlayer.feature, featureandlayer.layer);
  const fprops = featureandlayer.feature.getProperties();

  switch (pfilter) {
    case 'erscheinung_jahr.bofo_id': // Sonderfall Erscheinungsjahr
      headerstring = 'Erscheinungsjahr KB ' + fprops.kb_id;

      contentstring = '<table class="popup-ejahr">';
      if (fprops.name) {
        contentstring += '<tr><td>Name:</td><td>' + fprops.name + '</td></tr>';
      }
      if (fprops.kartierer) {
        contentstring +=
          '<tr><td>Kartierer:</td><td>' + fprops.kartierer + '</td></tr>';
      }
      if (fprops.kartierung_jahr) {
        contentstring +=
          '<tr><td>Kartierungsjahr:</td><td>' +
          fprops.kartierung_jahr +
          '</td></tr>';
      }
      if (fprops.erscheinung_jahr) {
        contentstring +=
          '<tr><td>Erscheinungsjahr:</td><td>' +
          fprops.erscheinung_jahr +
          '</td></tr>';
      }
      contentstring += '</table>';

      const maxw = document.querySelector('#map').offsetWidth;

      let popwidth = 400;
      if (maxw < 420) {
        popwidth = maxw - 20;
      }

      odimensions = {'width': popwidth, 'height': 160};
      popitup(
        headerstring,
        contentstring,
        odimensions,
        map,
        e.coordinate,
        e.pixel
      );

      break;
    default:
      if (typeof fprops.prof7_id == 'undefined') {
        // Layer...
        if (
          fprops.layer == 'bodenform_mpoly' &&
          layers.bodenform_mpoly.type == 'bofo_id'
        ) {
          // Bodenkarte - Bodenformen
          fetch('data/bodenformen/' + fprops.bofo_id + '.json')
            .then((resp) => resp.json())
            .then(function (data) {
              for (const elem in data) {
                if (typeof data[elem] === 'string') {
                  data[elem] = data[elem].replace(/\|/g, '<br>');
                }
              }
              const maxw = document.querySelector('#map').offsetWidth;
              const maxh = document.querySelector('#map').offsetHeight;

              let kbid = fprops.bofo_id.toString();
              const bfid = kbid.substr(kbid.length - 3);
              kbid = kbid.substr(0, kbid.length - 3);

              headerstring =
                'Beschreibung der Bodenform - ID ' +
                parseInt(bfid) +
                ' | KB ' +
                parseInt(kbid) +
                ' | Bodentyp ' +
                fprops.kurzbezeichnung;
              contentstring = bofoBeautify(data);
              if (
                data.flaeche_typ == 'Bodenformkomponente' ||
                data.flaeche_typ == 'Bodenformkomplex'
              ) {
                contentstring =
                  '<div class="khinweis">Hinweis: Hauptbodenform dieser Fläche</div>' +
                  contentstring;
              }
              let popwidth = maxw - 20;
              if (maxw > 550) {
                popwidth = Math.min(550, maxw - 300);
              }
              let popheight = maxh - 20;
              if (maxh > 550) {
                popheight = Math.min(650, maxh - 100);
              }

              odimensions = {'width': popwidth, 'height': popheight};

              // Add image, if there...
              fetch('data/AlleBodenformProfile/' + fprops.bofo_id + '.jpg', {
                method: 'HEAD',
              })
                .then(function (response) {
                  if (response.ok) {
                    contentstring =
                      '<img src="data/AlleBodenformProfile/' +
                      fprops.bofo_id +
                      '.jpg">' +
                      contentstring;
                  }
                  popitup(
                    headerstring,
                    contentstring,
                    odimensions,
                    map,
                    e.coordinate,
                    e.pixel,
                    true,
                    false,
                    true
                  );
                })
                .catch(function () {
                  popitup(
                    headerstring,
                    contentstring,
                    odimensions,
                    map,
                    e.coordinate,
                    e.pixel,
                    true,
                    false,
                    true
                  );
                });
            })
            .catch(function () {
              alert(
                'Ein Fehler ist bei der Server-Anfrage aufgetreten. Bitte versuchen Sie es nocheinmal oder informieren Sie den Website-Inhaber. Danke!'
              );
            });
        } else if (fprops.layer == 'sonstigeflaechen_mpoly') {
          // Sonstige Fläche
          headerstring = 'Sonstige Flächen';
          contentstring =
            '<span class="coloredbox" style="opacity: ' +
            bodenkarte.getOpacity() +
            '; background-color: ' +
            legendenHash['sonstigeflaechen'][fprops.code].color +
            ';"></span>' +
            legendenHash['sonstigeflaechen'][fprops.code].legende;
          popitup(
            headerstring,
            contentstring,
            odimensions,
            map,
            e.coordinate,
            e.pixel
          );
        } else {
          // Alle anderen
          headerstring =
            '<span class="ellipsed">' +
            layerNames[layers.bodenform_mpoly.type] +
            '</span>';
          headerstring += '<br><i class="leftspacer"></i>ID ' + fprops.bofo_id;
          contentstring =
            '<span class="coloredbox" style="opacity: ' +
            bodenkarte.getOpacity() +
            '; background-color: ' +
            legendenHash[layers.bodenform_mpoly.type][
              fprops[layers.bodenform_mpoly.type]
            ].color +
            ';"></span>' +
            legendenHash[layers.bodenform_mpoly.type][
              fprops[layers.bodenform_mpoly.type]
            ].legende;
          popitup(
            headerstring,
            contentstring,
            odimensions,
            map,
            e.coordinate,
            e.pixel
          );
        }
      } else {
        // Profilstelle!
        const urls = [
          'data/profile/' + fprops.prof7_id + '.json',
          'data/horizonte/' + fprops.prof7_id + '.json',
        ];
        Promise.all(urls.map((url) => fetch(url).then((resp) => resp.json())))
          .then(function (data) {
            for (let p = 0; p < data.length; p++) {
              for (const elem in data[p]) {
                if (typeof data[p][elem] === 'string') {
                  data[p][elem] = data[p][elem].replace(/\|/g, '<br>');
                }
                if (typeof data[p][elem] === 'object') {
                  for (const subelem in data[p][elem]) {
                    if (typeof data[p][elem][subelem] === 'string') {
                      data[p][elem][subelem] = data[p][elem][subelem].replace(
                        /\|/g,
                        '<br>'
                      );
                    }
                  }
                }
              }
            }
            const maxw = document.querySelector('#map').offsetWidth;
            const maxh = document.querySelector('#map').offsetHeight;

            let kbid = fprops.prof7_id.toString();
            let kziffer = kbid.substr(kbid.length - 1);
            kbid = kbid.substr(0, kbid.length - 1); // Letzte Ziffer = Kennziffer, noch ohne Verwendung
            const bfid = kbid.substr(kbid.length - 3);
            kbid = kbid.substr(0, kbid.length - 3);

            if (parseInt(kziffer) > 0) {
              kziffer = '-K' + kziffer;
            } else {
              kziffer = '';
            }
            headerstring =
              'Profil der Bodenform - ID ' +
              parseInt(bfid) +
              kziffer +
              ' | KB ' +
              parseInt(kbid);
            contentstring = profilstelleBeautify(data);

            let popwidth = maxw - 20;
            if (maxw > 550) {
              popwidth = Math.min(550, maxw - 300);
            }
            let popheight = maxh - 30;
            if (maxh > 550) {
              popheight = Math.min(650, maxh - 100);
            }

            odimensions = {'width': popwidth, 'height': popheight};
            popitup(
              headerstring,
              contentstring,
              odimensions,
              map,
              e.coordinate,
              e.pixel,
              true,
              false,
              true
            );
          })
          .catch(function () {
            alert(
              'Ein Fehler ist bei der Server-Anfrage aufgetreten. Bitte versuchen Sie es nocheinmal oder informieren Sie den Website-Inhaber. Danke!'
            );
          });
      }
      break;
  }
}

/* popitup - pops the overlay up */
export function popitup(
  headerstring,
  contentstring,
  dimensions,
  map,
  coordinate,
  pixelpos,
  scrollable,
  exactlythere,
  printable
) {
  hideunwantedstuff(false, true);
  map.getView().once('change:resolution', function () {
    popdown();
  });
  popuptarget.style.width = dimensions.width + 'px';
  popuptarget.style.height = dimensions.height + 'px';
  popuptarget.innerHTML = markup({});
  if (scrollable) {
    popuptarget.querySelector('.popupcontent').classList.add('scrollable');
  } else {
    popuptarget.querySelector('.popupcontent').classList.remove('scrollable');
  }
  popuptarget.querySelector('#popuphead').innerHTML = headerstring;
  popuptarget.querySelector('.popupcontent').innerHTML = contentstring;
  popuptarget.addEventListener('click', close.bind(close));

  if (printable) {
    popuptarget.querySelector('.popupcontent').classList.add('printable');
    popuptarget.querySelector('.printme').classList.add('printable');
    popuptarget.querySelector('.printme').addEventListener('click', printpopup);
  }
  // Now calculate the position of the popup - or take it directly...
  const mapw = document.querySelector('.map').clientWidth;
  const maph = document.querySelector('.map').clientHeight;
  let px = 10;
  let py = 10;

  if (exactlythere) {
    px = pixelpos[0];
    py = pixelpos[1];
  } else {
    // Horizontal...
    // Links?
    if (pixelpos[0] - (dimensions.width + 10) > 0) {
      px = pixelpos[0] - dimensions.width - 10;
    }
    // Lieber Rechts...
    if (mapw - (dimensions.width + 10) > pixelpos[0]) {
      px = pixelpos[0] + 10;
    }

    // Vertikal
    py = pixelpos[1] - parseInt(dimensions.height / 2);
    if (py + dimensions.height > maph) {
      py = maph - dimensions.height - 10;
    }
    if (py < 0) {
      py = 10;
    }
  }

  popuptarget.style.left = px + 'px';
  popuptarget.style.top = py + 'px';

  const mapol = document.querySelector('#map');
  mapol.appendChild(popuptarget);

  const popupw = document.querySelector('.popup');
  const dragopts = {
    handle: popupw.querySelector('.draghead'),
    limit: {
      x: [40 - dimensions.width, mapw - 40],
      y: [0, maph - 40],
    },
  };

  new Draggable(popupw, dragopts);
  //console.log(map, dimensions, pixelpos); //eslint-disable-line
}

/*
 * Closes an open popup, if any.
 */
export function popdown(map) {
  popuptarget.removeEventListener('click', close);
  const mapol = document.querySelector('#map');
  const popupdiv = mapol.querySelector('.popup');
  if (popupdiv) {
    mapol.removeChild(popupdiv);
  }
  unhighlight();
}

/* Initialize PopUp stuff */
export function initPopUp(map) {
  map.on('click', function (e) {
    const view = map.getView();
    if (
      layers.kartierungsbereich_mpoly.type != 'erscheinung_jahr' &&
      view.getZoom() < 13 &&
      isInAustria(e.coordinate)
    ) {
      view.animate(
        {
          anchor: e.coordinate,
          zoom: 13,
        },
        () => {
          popup(map, e);
        }
      );
    } else {
      popup(map, e);
    }
    return false;
  });
}

// printpopup
// prints content of printable popup window!
function printpopup() {
  const printcontent = document.querySelector(
    '.popupcontent.printable'
  ).innerHTML;

  const mywindow = window.open('', 'PRINT', 'height=400,width=600');

  mywindow.document.write(
    '<html><head><title>' +
      document.querySelector('#popuphead').innerText +
      '</title>'
  );
  mywindow.document.write('<link href="style.css" rel="stylesheet">');
  mywindow.document.write(
    '</head><body ><div class="popupcontent printcontent">'
  );
  mywindow.document.write(
    '<div class="printheader">' +
      document.querySelector('#popuphead').innerText +
      '</div>'
  );
  mywindow.document.write(printcontent);
  mywindow.document.write(
    '</div><div class="printmenu"><div onClick="document.close();window.print(); window.close();"><i class="usericon icon-print"></i> Drucken</div><div onClick="window.close();"><i class="usericon hover icon-close-1"></i> Schließen</div></div'
  );
  mywindow.document.write('</body></html>');

  //mywindow.document.close(); // necessary for IE >= 10
  //mywindow.focus(); // necessary for IE >= 10*/

  //mywindow.print();
  //mywindow.close();

  return true;
}
