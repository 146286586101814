import './pdf.css';
import GeoJSON from 'ol/format/GeoJSON';
import Map from 'ol/Map';
import Vector from 'ol/source/Vector';
import VectorLayer from 'ol/layer/Vector';
import View from 'ol/View';
import jsPDF from 'jspdf';
import {bodenkarte} from './maplayers.js';
import {calculateColumnSizes} from './pdfHelpers.js';
import {legendItems} from './legend.js';
import {overViewStyle} from './style.js';
import {spinnercontrol} from './searchbar.js';
import {unByKey} from 'ol/Observable';

function composeMap(map) {
  const mapCanvas = document.createElement('canvas');
  const size = map.getSize();
  mapCanvas.width = size[0];
  mapCanvas.height = size[1];
  const mapContext = mapCanvas.getContext('2d');
  const layers = map
    .getViewport()
    .querySelectorAll('.ol-layer canvas, canvas.ol-layer');
  layers.forEach((canvas) => {
    const style = getComputedStyle(canvas);
    const opacity = style.opacity;
    mapContext.globalAlpha = opacity ? Number(opacity) : 1;
    const transform = style.transform;
    if (transform !== 'none') {
      const matrix = transform
        .match(/^matrix\(([^\(]*)\)$/)[1]
        .split(',')
        .map(Number);
      mapContext.setTransform.apply(mapContext, matrix);
    }
    if (canvas.width > 0) {
      mapContext.drawImage(canvas, 0, 0);
    }
  });
  return mapCanvas;
}

/**
 * create and download page as pdf
 * @param {module:ol/CanvasMap~CanvasMap} map The map to create the measure interaction for.
 * @param {string} format pdf size format (DIN)
 */
export function initPDF(map, format) {
  // init toolbox button
  const dims = {
    a0: [1189, 841],
    a1: [841, 594],
    a2: [594, 420],
    a3: [420, 297],
    a4: [297, 210],
    a5: [210, 148],
  };

  const overviewMapContainer = document.createElement('div');

  document.getElementById('pdfButton').addEventListener('click', (evt) => {
    const fachcolors = {
      'bfw': [0, 103, 66],
      'bfa': [0, 128, 0],
      'baw': [19, 95, 142],
    };
    let fachkarte = 'bfw';
    if (document.querySelector('body').classList.contains('bfa')) {
      fachkarte = 'bfa';
    }
    if (document.querySelector('body').classList.contains('baw')) {
      fachkarte = 'baw';
    }
    const exportButton = evt.target;
    exportButton.disabled = true;
    spinnercontrol(1);

    function downloadPDF(dataURL) {
      const formatHeight = dims[format][1];
      const formatWidth = dims[format][0];

      const i = new Image();
      i.onload = function () {
        let imageWidth = i.width;
        const originalImageWidth = i.width;
        let imageHeight = i.height;
        const orientation = imageWidth > imageHeight ? 'landscape' : 'portrait';
        const pdf = new jsPDF(orientation, undefined, format);
        const padding = 12; //abstand zum Rand
        let ratio;
        //make image fit, if image-width is larger than format-width
        if (imageWidth > formatWidth - 2 * padding) {
          ratio = (formatWidth - 2 * padding) / imageWidth;
          imageWidth = Math.floor(imageWidth * ratio);
          imageHeight = Math.floor(imageHeight * ratio);
        }
        //after setting the width, check again if height is (still) too large
        if (imageHeight > formatHeight - 7.5 * padding) {
          ratio = (formatHeight - 7.5 * padding) / imageHeight;
          imageWidth = Math.floor(imageWidth * ratio);
          imageHeight = Math.floor(imageHeight * ratio);
        }

        // paddings for final position
        const horizontalPadding = 12;
        const verticalPadding = 26;
        pdf.addImage(
          dataURL,
          'PNG',
          horizontalPadding,
          verticalPadding,
          imageWidth,
          imageHeight
        );

        // get scale bar length from original map and create a new resized scale bar
        const scaleLine = document.querySelector('.ol-scale-line-inner');
        const scaleLineWidth = scaleLine.offsetWidth;
        const downsizing = imageWidth / originalImageWidth;
        const pdfScaleLine = document.createElement('canvas');
        pdfScaleLine.width = scaleLineWidth;
        pdfScaleLine.height = 28;
        const pdfScaleLineCtx = pdfScaleLine.getContext('2d');
        // add semi-transparent background
        pdfScaleLineCtx.fillStyle = 'rgba(255, 255, 255, 0.6)';
        pdfScaleLineCtx.fillRect(0, 0, scaleLineWidth, 28);
        //add lines
        pdfScaleLineCtx.beginPath();
        pdfScaleLineCtx.moveTo(1, 24);
        pdfScaleLineCtx.lineTo(1, 1);
        pdfScaleLineCtx.lineTo(scaleLineWidth - 1, 1);
        pdfScaleLineCtx.lineTo(scaleLineWidth - 1, 28);
        pdfScaleLineCtx.strokeStyle = '#323232';
        pdfScaleLineCtx.lineWidth = 2;
        pdfScaleLineCtx.stroke();

        const pdfScaleLineURL = pdfScaleLine.toDataURL('image/png');
        const scaleLineText = scaleLine.innerText;

        const newScaleLineWidth = scaleLineWidth * downsizing;
        const newScaleLineHeight = 28 * downsizing;
        pdf.addImage(
          pdfScaleLineURL,
          'PNG',
          16,
          140,
          newScaleLineWidth,
          newScaleLineHeight
        );

        //const newScaleLineText = textNumbers + textUnits;
        // add measurement for the scale line
        const scaleLinePadding = 16 + newScaleLineWidth / 2;
        pdf.setFont('helvetica');
        pdf.setFontSize(7);
        pdf.setTextColor(50, 50, 50);
        pdf.text(scaleLineText, scaleLinePadding, 142.3, 'center');

        // decorative line underneath map, see layout
        pdf.setFillColor(
          fachcolors[fachkarte][0],
          fachcolors[fachkarte][1],
          fachcolors[fachkarte][2]
        );
        pdf.rect(
          horizontalPadding,
          verticalPadding + imageHeight,
          imageWidth,
          1.5,
          'F'
        );

        // add bfw tree lgo
        const bfwTreePromise = new Promise((resolve, reject) => {
          const bfwLogo = document.createElement('canvas');
          bfwLogo.width = 800;
          bfwLogo.height = 200;
          const bfwContext = bfwLogo.getContext('2d');
          const bfwImage = new Image(177, 200);
          bfwImage.onload = function () {
            bfwContext.drawImage(bfwImage, 0, 0);
            const logoDataURL = bfwLogo.toDataURL('image/png');
            const treeWidth = bfwLogo.width / 20;
            const treeHeight = bfwLogo.height / 20;
            pdf.addImage(
              logoDataURL,
              'PNG',
              padding,
              padding,
              treeWidth,
              treeHeight
            );
            resolve(true);
          };
          bfwImage.src = './data/bfw-logo-hoch.png';
        });
        // add EBOD-logo
        const ebodPromise = new Promise((resolve, reject) => {
          const ebodLogo = document.createElement('canvas');
          ebodLogo.width = 567;
          ebodLogo.height = 189;
          const ebodContext = ebodLogo.getContext('2d');
          const ebodImage = new Image(ebodLogo.width, ebodLogo.height);
          ebodImage.onload = function () {
            ebodContext.drawImage(ebodImage, 0, 0);
            const ebodLogoDataURL = ebodLogo.toDataURL('image/png');
            pdf.addImage(
              ebodLogoDataURL,
              'PNG',
              padding + 11,
              padding,
              ebodLogo.width / 18,
              ebodLogo.height / 18
            );

            // add logotext
            pdf.setFont('helvetica');
            pdf.setFontSize(17);
            pdf.setFontStyle('bold');
            pdf.setTextColor(
              fachcolors[fachkarte][0],
              fachcolors[fachkarte][1],
              fachcolors[fachkarte][2]
            );
            if (fachkarte == 'bfw') {
              pdf.text(
                'Digitale Bodenkarte',
                ebodLogo.width / 18 + padding + 13,
                20
              );
            } else if (fachkarte == 'bfa') {
              pdf.text(
                'Bio Forschung Austria',
                ebodLogo.width / 18 + padding + 13,
                20
              );
            } else if (fachkarte == 'baw') {
              pdf.text(
                'Bundesamt für Wasserwirtschaft',
                ebodLogo.width / 18 + padding + 13,
                20
              );
            }
            resolve(true);
          };
          ebodImage.src = './data/logo-ebod-' + fachkarte + '.svg';
        });

        // add BFW-Logo
        const bfwPromise = new Promise((resolve, reject) => {
          const bfwLogo = document.createElement('canvas');
          bfwLogo.width = 800;
          bfwLogo.height = 200;
          const bfwContext = bfwLogo.getContext('2d');
          const bfwImage = new Image(600, 200);
          bfwImage.onload = function () {
            bfwContext.drawImage(bfwImage, 0, 0);
            const logoDataURL = bfwLogo.toDataURL('image/png');
            const BFWWidth = bfwLogo.width / 15;
            const BFWHeight = bfwLogo.height / 15;
            pdf.addImage(
              logoDataURL,
              'PNG',
              formatWidth - (BFWWidth + padding),
              padding - 2,
              BFWWidth,
              BFWHeight
            );
            resolve(true);
          };
          bfwImage.src = './data/logo-pdf-' + fachkarte + '.png';
        });

        // add disclaimer
        pdf.setFont('helvetica');
        pdf.setFontSize(6);
        pdf.setFontStyle('normal');
        pdf.setTextColor(100, 100, 100);
        const disclaimertext = [
          'Die Karten und Text sind informations- ',
          'material für die Öffentlichkeit, keine',
          'amtliche Auskunft oder rechts-',
          'verbindliche Aussage.',
        ];
        pdf.text(disclaimertext, formatWidth - 67, 187.5, 'right');

        // add copyright
        const copyrightString = getDateString() + ' | © 2018 BFW';
        pdf.text(
          copyrightString,
          formatWidth - 67,
          formatHeight - padding,
          'right'
        );

        // add decorative line between disclaimer and overview map
        pdf.setFillColor(100, 100, 100);
        pdf.rect(233, 148.5, 0.2, 50, 'F');

        // add contacts
        pdf.setFontSize(8);
        const contacts = [
          'eBod2@bfw.gv.at',
          'Bundesforschungszentrum für Wald',
          'Seckendorff-Gudent-Weg 8, 1131 Wien',
        ];
        pdf.text(contacts, formatWidth - padding, formatHeight - 18.5, 'right');

        //add link
        pdf.setFontStyle('bold');
        pdf.setTextColor(30, 30, 30);
        pdf.text(
          'www.bodenkarte.at',
          formatWidth - padding,
          formatHeight - 22,
          'right'
        );

        // create legend
        // heading of first layer group
        const headerFontSize = 10;
        pdf.setFontSize(headerFontSize);
        pdf.setFontStyle('bold');
        pdf.setTextColor(
          fachcolors[fachkarte][0],
          fachcolors[fachkarte][1],
          fachcolors[fachkarte][2]
        );
        let headerText = legendItems.headingText;
        if (headerText === 'Bodenformen mit Profilstellen:') {
          // better Solution to handle length?
          headerText = 'Bodenformen:';
        }
        pdf.text(headerText, padding, 150, 'left');
        const textBuffer = 11.5;
        const headerLength =
          (pdf.getStringUnitWidth(headerText, {fontSize: headerFontSize}) *
            headerFontSize) /
            (72 / 25.6) +
          textBuffer;
        // rows of first layer group
        const legendTextSize = 8;
        pdf.setFontSize(legendTextSize);
        pdf.setFontStyle('normal');
        pdf.setTextColor(100, 100, 100);
        const initialIconLeft = padding;
        let iconLeft = initialIconLeft; //initial values of icon positions
        const initialIconTop = 152;
        let iconTop = initialIconTop;
        const iconSize = 4;
        const layerGroupDivs = legendItems.layerDivs; // collection of divs from legend, contain icon-canvas and layer name
        const {letters: textLetters, size: textSizes} = calculateColumnSizes(
          layerGroupDivs,
          pdf,
          textBuffer,
          legendTextSize,
          headerLength
        );
        for (let j = 0, jj = layerGroupDivs.length; j < jj; j++) {
          if (iconTop > 201) {
            iconLeft += textSizes[Math.floor(j / 9) - 1] + textBuffer; // get the correct size for this column
            iconTop = initialIconTop;
          }
          const maxLettersThisColumn = textLetters[Math.floor(j / 9)];
          // emulate the opacity by drawing another semi-opaque rectangle ebove icon, if not Profilstelle
          const iconText = layerGroupDivs[j].textContent;
          if (iconText !== 'Profilstelle') {
            const legendCanvas = layerGroupDivs[j].firstChild;
            const ctx = legendCanvas.getContext('2d');
            ctx.fillStyle = '#FFFFFF';
            ctx.globalAlpha = 1 - bodenkarte.getOpacity();
            ctx.fillRect(0, 0, legendCanvas.width, legendCanvas.height);
          }
          const iconCanvasURL = layerGroupDivs[j].firstChild.toDataURL();
          pdf.addImage(
            iconCanvasURL,
            'PNG',
            iconLeft,
            iconTop,
            iconSize,
            iconSize
          );
          if (iconText.length > maxLettersThisColumn) {
            // if text is too long, make it into array to place them both
            pdf.text(
              iconText.substring(0, maxLettersThisColumn) + '-',
              iconLeft + 4.5,
              iconTop + 1.5,
              'left'
            );
            let secondLine = iconText.substring(
              maxLettersThisColumn,
              maxLettersThisColumn * 2
            ); // if still too long, make point at the end
            if (secondLine.length === maxLettersThisColumn) {
              secondLine =
                secondLine.substring(0, maxLettersThisColumn - 1) + '.';
            }
            pdf.text(secondLine, iconLeft + 4.5, iconTop + 4, 'left');
          } else {
            pdf.text(iconText, iconLeft + 4.5, iconTop + 2.8, 'left');
          }
          iconTop += 5.5;
          if (j === 34) {
            const notShownItems = layerGroupDivs.length - 36;
            if (notShownItems > 0) {
              const overflowText = '...und ' + notShownItems + ' weitere';
              pdf.text(overflowText, iconLeft + 4.5, iconTop + 3, 'left');
            }
            break;
          }
        }
        // second layer group
        iconLeft += textSizes[textSizes.length - 1] + textBuffer || 0;
        iconTop = initialIconTop;
        if (legendItems.sonstigeFlaechen) {
          // heading
          pdf.setFontSize(10);
          pdf.setFontStyle('bold');
          pdf.setTextColor(
            fachcolors[fachkarte][0],
            fachcolors[fachkarte][1],
            fachcolors[fachkarte][2]
          );
          pdf.text('Sonstige Flächen:', iconLeft, 150, 'left');

          // rows of second layer group
          pdf.setFontSize(8);
          pdf.setFontStyle('normal');
          pdf.setTextColor(100, 100, 100);
          const sonstigeFlaechenDivs = legendItems.sonstigeFlaechenDivs;
          for (let j = 0, ii = sonstigeFlaechenDivs.length; j < ii; j++) {
            if (iconTop > 201) {
              iconLeft += 35;
              iconTop = initialIconTop;
            }
            const iconText = sonstigeFlaechenDivs[j].textContent;
            if (iconText !== 'Profilstelle') {
              const ctx = sonstigeFlaechenDivs[j].firstChild.getContext('2d');
              ctx.fillStyle = '#FFFFFF';
              ctx.globalAlpha = 1 - bodenkarte.getOpacity();
              ctx.fillRect(0, 0, 18, 18);
            }
            const iconCanvasURL =
              sonstigeFlaechenDivs[j].firstChild.toDataURL();
            pdf.addImage(
              iconCanvasURL,
              'PNG',
              iconLeft,
              iconTop,
              iconSize,
              iconSize
            );
            if (iconText.length > 30) {
              // if text is too long, make it into array to place them both
              pdf.text(
                iconText.substring(0, 30) + '-',
                iconLeft + 4.5,
                iconTop + 1.5,
                'left'
              );
              let secondLine = iconText.substring(30, 60); // if still too long, make point at the end
              if (secondLine.length === 30) {
                secondLine = secondLine.substring(0, 29) + '.';
              }
              pdf.text(secondLine, iconLeft + 4.5, iconTop + 4, 'left');
            } else {
              pdf.text(iconText, iconLeft + 4.5, iconTop + 2.8, 'left');
            }
            iconTop += 5.5;
          }
        }

        // create overview map
        overviewMapContainer.className = 'overview-map';
        document.body.appendChild(overviewMapContainer);

        const laenderSource = new Vector();
        fetch('data/laender.geojson').then((result) => {
          result.json().then((json) => {
            const geoJSON = new GeoJSON();
            const features = geoJSON.readFeatures(json, {
              featureProjection: 'EPSG:3857',
            });
            laenderSource.addFeatures(features);
          });
        });

        const onChangeKey = laenderSource.on('change', function () {
          if (laenderSource.getState() === 'ready') {
            unByKey(onChangeKey);

            const laenderBorders = new VectorLayer({
              source: laenderSource,
              style: overViewStyle,
            });
            const austriaView = new View();
            const overviewMap = new Map({
              target: overviewMapContainer,
              view: austriaView,
              controls: [],
              interactions: [],
              layers: [laenderBorders],
            });
            austriaView.fit(laenderSource.getExtent(), overviewMap.getSize());

            overviewMap.once('postcompose', function (event) {
              // rendercomplete doesn't work without tiles
              const canvas = composeMap(event.target);
              const dataURL = canvas.toDataURL();
              const centerPixel = overviewMap.getPixelFromCoordinate(
                map.getView().getCenter()
              );
              const extent = map.getView().calculateExtent(map.getSize());
              const bottomLeft = overviewMap.getPixelFromCoordinate([
                extent[0],
                extent[1],
              ]);
              const topRight = overviewMap.getPixelFromCoordinate([
                extent[2],
                extent[3],
              ]);
              // negative values make extent-rectangle to be drawn outside of canvas
              bottomLeft[0] = bottomLeft[0] < 1 ? 1 : bottomLeft[0];
              bottomLeft[1] = bottomLeft[1] < 1 ? 1 : bottomLeft[1];
              topRight[0] = topRight[0] < 1 ? 1 : topRight[0];
              topRight[1] = topRight[1] < 100 ? 100 : topRight[1];

              const overviewMapPromise = new Promise((resolve, reject) => {
                overviewMapReady(
                  dataURL,
                  centerPixel,
                  bottomLeft,
                  topRight,
                  resolve
                );
              });

              // wait for all async actions to be completed
              Promise.all([
                bfwTreePromise,
                ebodPromise,
                bfwPromise,
                overviewMapPromise,
              ]).then((values) => {
                pdf.save('map.pdf');
                map.updateSize();
              });

              overviewMap.setTarget(null);
            });
          }

          // when source is ready and map is in the making, wait for map to be
          // ready and convert map to image
          function overviewMapReady(
            dataURLoverviewMap,
            centerPixel,
            bottomLeft,
            topRight,
            resolve
          ) {
            const overviewMapImage = new Image();
            overviewMapImage.onload = function () {
              pdf.addImage(dataURLoverviewMap, 'PNG', 238, 140, 48, 48);

              //rectangle of extent
              let crossWidth = topRight[0] - bottomLeft[0];
              if (bottomLeft[0] + crossWidth > 400) {
                crossWidth = 400 - bottomLeft[0];
              }
              let crossHeight = bottomLeft[1] - topRight[1];
              if (topRight[1] + crossHeight > 300) {
                crossHeight = 300 - topRight[1];
              }
              // create new canvas for crosshair
              const crosshairCanvas = document.createElement('canvas');
              crosshairCanvas.width = 400;
              crosshairCanvas.height = 400;
              const ctx = crosshairCanvas.getContext('2d');
              ctx.beginPath();
              ctx.strokeStyle = '#777777';
              if (crossWidth < 200) {
                // dont make crosshair when zoomed out
                ctx.moveTo(centerPixel[0], 100); // vertical line
                ctx.lineTo(centerPixel[0], 300);
                ctx.stroke();
                ctx.moveTo(0, centerPixel[1]); // horizontal line
                ctx.lineTo(400, centerPixel[1]);
                ctx.stroke();
              }
              ctx.rect(bottomLeft[0], topRight[1], crossWidth, crossHeight);
              ctx.stroke();

              const crossHairImage = crosshairCanvas.toDataURL();
              pdf.addImage(crossHairImage, 'PNG', 238, 140, 48, 48);
              document.body.style.cursor = 'auto';
              spinnercontrol(0);
              overviewMapContainer.remove();
              resolve(true);
            };
            overviewMapImage.src = dataURLoverviewMap;
          }
        });
      };
      i.src = dataURL;
    }

    // create new, larger map, new view needs to include everything of current view,
    // but proportions need to be according to layout

    map.once('rendercomplete', function (event) {
      const mapCanvas = composeMap(event.target);
      const dataURL = mapCanvas.toDataURL();
      downloadPDF(dataURL);
      map.getTargetElement().classList.remove('pdf-layout-map');
    });
    map.getTargetElement().classList.add('pdf-layout-map');
  });
}

function getDateString() {
  const today = new Date();
  let dd = today.getDate();
  let mm = today.getMonth() + 1; //January is 0!
  const yyyy = today.getFullYear();

  if (dd < 10) {
    dd = '0' + dd;
  }

  if (mm < 10) {
    mm = '0' + mm;
  }

  const dateString = dd + '.' + mm + '.' + yyyy;
  return dateString;
}
