<span class="info-menu-row bfa baw first" for="bfw/aktuelles">Aktuelles</span>
<span class="info-menu-row bfa baw" for="bfw/ueberuns">Über Uns</span>
<span class="info-menu-row bfa baw" for="bfw/karteninhalte">Karteninhalte</span>
<span class="info-menu-row bfa baw" for="bfw/datenzugang">Datenzugang</span>
<span class="info-menu-row bfa baw" for="bfw/kontaktimpressum">Kontakt und Impressum</span>

<span class="info-menu-row bfw baw first" for="bfa/aktuelles">Aktuelles</span>
<span class="info-menu-row bfw baw" for="bfa/ueberuns">Über Uns</span>
<span class="info-menu-row bfw baw" for="bfa/karteninhalte">Karteninhalte</span>
<span class="info-menu-row bfw baw" for="bfa/datenzugang">Datenzugang</span>
<span class="info-menu-row bfw baw" for="bfa/kontaktimpressum">Kontakt und Impressum</span>

<span class="info-menu-row bfw bfa first" for="baw/aktuelles">Aktuelles</span>
<span class="info-menu-row bfw bfa" for="baw/ueberuns">Über Uns</span>
<span class="info-menu-row bfw bfa" for="baw/karteninhalte">Karteninhalte</span>
<span class="info-menu-row bfw bfa" for="baw/datenzugang">Datenzugang</span>
<span class="info-menu-row bfw bfa" for="baw/kontaktimpressum">Kontakt und Impressum</span>
