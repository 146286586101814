<div class="layer-head"><div><i class="usericon icon-map"></i>&nbsp;Kartensteuerung</div><i for="layermenu" class="usericon icon-close-1 hover closethis"></i></div>

<div class="scrollable-ondemand">
  ${scope.map((group, i) => `
    <div class="layer-section" id="layersection${i + 1}">
      <div class="layer-section-head layerswitch" for="${i + 1}"><i class="usericon iconclosed icon-right-open"></i><i class="usericon iconopen icon-down-open"></i>&nbsp;${group.title}<div class="sliderbox"></i><input class="opacity-slider" type="range" min="0" max="100" value="60" /></div></div>
      ${group.topics.map(topic => `
        <div class="onerow layerswitch radio" target="${topic.name}"><i class="usericon unselected icon-radio-0"></i><i class="usericon selected icon-radio-1"></i>${topic.title}<br /><span>${topic.description}</span></div>
        `).join('')}
      <div style="display:${group.type !== 'raster' ? '' : 'none'}" class="onerow layerswitch checkbox sonstige section${i + 1}"><i class="usericon unselected icon-check-0"></i><i class="usericon selected icon-check-1-full"></i>Sonstige Flächen</div>
    </div>
    `).join('')}
    <div class="layer-section" id="layersection100">
      <div class="layer-section-head layerswitch noautoswitch"  for="100"><i class="usericon iconclosed icon-right-open"></i><i class="usericon iconopen icon-down-open"></i>&nbsp;<span id="themenswitchtext"></span></div>
      <div class="layerswitch fachkarte bfw" for="bfw"></div>
      <div class="layerswitch fachkarte baw" for="baw"></div>
      <div class="layerswitch fachkarte bfa" for="bfa"></div>
    </div>


  <div class="mapbutton layerswitch forauto selected" for="auto">
    <img src="./data/map-mode-auto.png" />
    <span>Automatisch</span>
  </div>
  <div class="mapbutton layerswitch fortopo" for="topo">
    <img src="./data/map-mode-topo.png" />
    <span>Topografische Karte</span>
  </div>
  <div class="mapbutton layerswitch forortho" for="ortho">
    <img src="./data/map-mode-ortho.png" />
    <span>Luftbild</span>
  </div>
</div>
