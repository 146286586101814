import Circle from 'ol/style/Circle';
import Fill from 'ol/style/Fill';
import Icon from 'ol/style/Icon';
import Stroke from 'ol/style/Stroke';
import Style from 'ol/style/Style';
import Text from 'ol/style/Text';
import {layers} from './layers.js';

/**
 * Hatch pattern for the `highlightStyle`
 */
const pattern = (function () {
  const pixelRatio = window.devicePixelRatio || 1;
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  const p = document.createElement('canvas');
  p.width = 6 * pixelRatio;
  p.height = 6 * pixelRatio;
  const pctx = p.getContext('2d');

  const x0 = 9 * pixelRatio;
  const x1 = -pixelRatio;
  const y0 = -pixelRatio;
  const y1 = 9 * pixelRatio;
  const offset = 6 * pixelRatio;

  pctx.strokeStyle = 'black';
  pctx.lineWidth = pixelRatio;
  pctx.beginPath();
  pctx.moveTo(x0, y0);
  pctx.lineTo(x1, y1);
  pctx.moveTo(x0 - offset, y0);
  pctx.lineTo(x1 - offset, y1);
  pctx.moveTo(x0 + offset, y0);
  pctx.lineTo(x1 + offset, y1);
  pctx.stroke();

  return ctx.createPattern(p, 'repeat');
})();

/**
 * Higlhight style for polygons.
 */
const highlightStyle = new Style({
  stroke: new Stroke({
    color: 'black',
    width: 1,
  }),
  fill: new Fill({
    color: pattern,
  }),
  zIndex: 99999,
});

const fill = new Fill();
const stroke = new Stroke({
  width: 0.5,
});
const text = new Text({
  fill: new Fill(),
  stroke: new Stroke(),
});
const style = new Style({
  fill: fill,
  stroke: stroke,
  text: text,
  zIndex: 1,
});
const outline = new Style({
  stroke: new Stroke({
    width: 1.5,
    color: 'black',
  }),
  zIndex: 0,
});

const iconCache = {};

/**
 * Get an icon. For all icons, a standard view (e.g. `icon.svg`) and a highlight
 * version (e.g. `icon_highligt.svg`) should be provided. The standard and
 * highlight icons are defined in `../layers` js as `icon` property for the
 * exported layer.
 * @param {Object} icon Icon from `../layers.js`,
 * @param {boolean} highlighted Use the higlighted version.
 * @return {Array<module:ol/style/Style~Style>} Style.
 */
function getIcon(icon, highlighted) {
  let srcProperty = 'src';
  let sizeProperty = 'size';
  if (highlighted) {
    srcProperty += '_highlight';
    sizeProperty += '_highlight';
  }
  const src = icon[srcProperty];
  if (!iconCache[src]) {
    iconCache[src] = [
      new Style({
        image: new Icon({
          src: `data/${src}.svg`,
          size: icon[sizeProperty],
          imgSize: icon[sizeProperty],
        }),
      }),
    ];
  }
  return iconCache[src];
}

const none = 'rgba(0, 0, 0, 0)';

const idForLayer = {};
const layerForName = {};

/**
 * Creates lookup maps for layers and id fields. This is required for proper
 * style function performance. Call this function with a map, after all layer
 * have been added to the map and configured properly.
 *
 * Proper layer configuration requires two properties on the layer: `idField`
 * and `name`. The `idField` is the feature property that contains a unique id
 * which is used for highlighting. `name` is the layer name which is used by
 * the style function to identify the correct layer for vector tile sources in
 * ´../style.js´, and by `../debug.layerswitcher.js`.
 *
 * @param {module:ol/Map~Map} map Map.
 */
export function registerLayers(map) {
  const layers = map.getLayers().getArray();
  for (let i = 0, ii = layers.length; i < ii; ++i) {
    const layer = layers[i];
    const idField = layer.get('idField');
    if (!idField) {
      continue;
    }
    let name = layer.get('name');
    name = Array.isArray(name) ? name : [name];
    for (let j = 0, jj = name.length; j < jj; ++j) {
      idForLayer[name[j]] = idField;
      layerForName[name[j]] = layer;
    }
  }
}

const highlightByLayer = {};

/*
 * Highlight a feature
 */
export function highlight(feature, layer) {
  const id = feature.get(layer.get('idField'));
  if (id != undefined) {
    highlightByLayer[feature.get('layer') || layer.get('name')] = id;
    layer.changed();
  } else {
    unhighlight();
  }
}

/*
 * Unhighlight
 */
export function unhighlight() {
  Object.keys(highlightByLayer).forEach((layerName) => {
    delete highlightByLayer[layerName];
    layerForName[layerName].changed();
  });
}

const layerOrder = Object.keys(layers);
const styles = [style];
const highlightedStyles = [style, highlightStyle];
const stylesWithOutline = [outline, style];
const highlightedStylesWithOutline = [outline, style, highlightStyle];

/**
 * Style function to be assigned as `style` to `VectorTile` and `Vector` layers.
 * @param {module:ol/Feature~Feature} feature Feature.
 * @param {number} resolution Resolution.
 * @return {Array<import("ol/style").Style>|undefined} Styles.
 * @this {module:ol/layer/Vector~Vector|undefined}
 */
export default function (feature, resolution) {
  const properties = feature.getProperties();
  // VectorTile layers get the layer name from the feature's `layer` property.
  // For Vector layers, the style function needs to be bound to the layer so
  // we can access the layer's name.
  const layer = properties.layer || this.get('name');
  const types = layers[layer];
  if (types && types.visible) {
    const render = properties[types.type];
    if (render !== undefined) {
      let highlighted = false;
      if (highlightByLayer[layer]) {
        highlighted = properties[idForLayer[layer]] == highlightByLayer[layer];
      }
      if (types.style) {
        const styleType = types.style[types.type];
        const styleDef =
          typeof styleType.getCategory == 'function'
            ? styleType.category[styleType.getCategory(render)]
            : styleType[render];
        const isObj = typeof styleDef != 'string';
        fill.setColor(isObj ? styleDef.fill || none : styleDef);
        stroke.setColor(isObj ? styleDef.stroke || none : styleDef);
        stroke.setWidth(styleDef.width || 0.5);
        if (styleDef.text) {
          style.setText(text);
          const field = styleDef.text.field;
          text.setText(
            properties[typeof field == 'function' ? field(resolution) : field] +
              ''
          );
          text.setFont(styleDef.text.font || '12px sans-serif');
          text.getFill().setColor(styleDef.text.fill || 'black');
          text.getStroke().setColor(styleDef.text.stroke || 'white');
          text.getStroke().setWidth(styleDef.text.width || 3);
        } else {
          style.setText(undefined);
        }
        const zIndex = 2 * layerOrder.indexOf(layer);
        outline.setZIndex(zIndex);
        style.setZIndex(zIndex + 1);
        // Use outline only when zoomed out to resolutions less than 30.
        return resolution < 30
          ? highlighted
            ? highlightedStylesWithOutline
            : stylesWithOutline
          : highlighted
          ? highlightedStyles
          : styles;
      } else if (types.icon) {
        return getIcon(types.icon, highlighted);
      }
    }
  }
}

/**
 * Style function for imported or drawn vector features.
 * @param {module:ol/Feature~Feature} feature Feature.
 * @return {Array<module:ol/style/Style~Style>} Styles.
 */
export function sketchStyleFunction(feature) {
  function createStyle(color, width) {
    const image = new Circle({
      radius: 5,
      fill: null,
      stroke: new Stroke({color: color, width: width}),
    });

    return {
      'Point': new Style({
        image: image,
      }),
      'LineString': new Style({
        stroke: new Stroke({
          color: color,
          width: width,
        }),
      }),
      'MultiLineString': new Style({
        stroke: new Stroke({
          color: color,
          width: width,
        }),
      }),
      'MultiPoint': new Style({
        image: image,
      }),
      'MultiPolygon': new Style({
        stroke: new Stroke({
          color: color,
          width: width,
        }),
      }),
      'Polygon': new Style({
        stroke: new Stroke({
          color: color,
          width: width,
        }),
      }),
      'GeometryCollection': new Style({
        stroke: new Stroke({
          color: color,
          width: width,
        }),
        fill: new Fill({
          color: color,
        }),
        image: new Circle({
          radius: 10,
          fill: null,
          stroke: new Stroke({
            color: color,
            width: width,
          }),
        }),
      }),
      'Circle': new Style({
        stroke: new Stroke({
          color: color,
          width: width,
        }),
      }),
    };
  }
  let color = '#fff';
  let width = 3;
  const underlyingStyle = createStyle(color, width);

  color = '#000';
  width = 1;
  const overlyingStyle = createStyle(color, width);

  const styleArray = [
    underlyingStyle[feature.getGeometry().getType()],
    overlyingStyle[feature.getGeometry().getType()],
  ];
  return styleArray;
}

export function labelStylefunction(feature) {
  const underlyingStyle = new Style({
    image: new Circle({
      radius: 5,
      fill: null,
      stroke: new Stroke({color: 'white', width: 3}),
    }),
  });

  const overlyingStyle = new Style({
    image: new Circle({
      radius: 5,
      fill: null,
      stroke: new Stroke({color: 'black', width: 1.5}),
    }),
    text: new Text({
      text: feature.get('text'),
      font: '16px Open Sans,sans-serif',
      fill: new Fill({
        color: '#000',
      }),
      offsetY: 11,
      offsetX: 8,
      textAlign: 'left',
      padding: [5, 5, 5, 5],
      stroke: new Stroke({
        color: '#fff',
        width: 3,
      }),
    }),
  });
  return [underlyingStyle, overlyingStyle];
}

export const overViewStyle = new Style({
  stroke: new Stroke({
    color: 'rgb(255, 255, 255)',
    width: 1,
  }),
  fill: new Fill({
    color: 'rgb(130, 130, 130)',
  }),
});
