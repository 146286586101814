/**
 * This module contains all controll routines for handling of the Layer Switcher
 */

import config from '../data/config.json';
import markup from './layermenu.thtml';
import {
  bmapGrau,
  bmapGrauMaxRes,
  bmapGrauMinRes,
  bmapOrtho,
  bmapOrthoMaxRes,
  bmapOrthoMinRes,
  bodenkarte,
  kartierungsbereich,
  rasterkarte,
} from './maplayers.js';
import {layers} from './layers.js';
import {map} from './map.js';
import {map_control} from './mapcontrols.js';
import {popdown, popuptarget} from './popup.js';
import {setDataDomain} from './layerhash.js';

const listeners = [];
const mapmodes = ['auto', 'topo', 'ortho'];

function addListener(target, type, fn) {
  listeners.push([target, type, fn]);
  target.addEventListener(type, fn);
}

function removeListeners() {
  while (listeners.length > 0) {
    const listener = listeners.pop();
    listener[0].removeEventListener(listener[1], listener[2]);
  }
}

export function initLayerMenu() {
  removeListeners();
  const container = document.querySelector('#layermenu');

  const domain = document.body.className;

  const groups = [];
  for (const key in config.topics) {
    const topic = config.topics[key];
    if (!topic.domain || topic.domain === domain) {
      if (!groups[topic.group]) {
        groups[topic.group] = {
          title: config.groups[domain][topic.group],
          topics: [],
        };
      }
      groups[topic.group].topics[topic.order] = {
        name: key,
        title: topic.title,
        description: topic.description,
      };
      if (topic.type === 'raster') {
        groups[topic.group].type = 'raster';
      }
    }
  }
  container.innerHTML = markup(groups);

  const closeButton = document.querySelector('.layer-head .closethis');
  addListener(closeButton, 'click', function (event) {
    map_control(this, 'switch');
  });

  const llist = document.querySelectorAll('.layerswitch');
  for (let e = 0; e < llist.length; e++) {
    addListener(llist[e], 'click', function (event) {
      layer_switch(this, event);
    });
  }

  const oslist = document.querySelectorAll('.opacity-slider');
  for (let o = 0; o < oslist.length; o++) {
    addListener(oslist[o], 'input', function (event) {
      opacity_slider(this.value, event);
    });
    addListener(oslist[o], 'change', function (event) {
      opacity_slider(this.value, event);
    });
  }

  // Init on base of layer status...
  let initarget = '';
  if (layers.kartierungsbereich_mpoly.type == 'erscheinung_jahr') {
    initarget = 'erscheinung_jahr';
  } else {
    initarget = layers.bodenform_mpoly.type;
  }
  if (rasterkarte.getVisible()) {
    initarget = 'erosionskarte';
  }

  const iniradio = document.querySelector('[target=' + initarget + ']');
  iniradio.classList.add('selected');
  const inisection = iniradio.parentNode;
  inisection.classList.add('section-open');

  if (layers.sonstigeflaechen_mpoly.visible) {
    inisection.querySelector('.sonstige').classList.add('selected');
  }

  // Init BG Map controls...
  let maptype = '';
  if (bmapOrtho.getVisible() && bmapGrau.getVisible()) {
    maptype = 'auto';
  }
  if (!bmapOrtho.getVisible() && bmapGrau.getVisible()) {
    maptype = 'topo';
  }
  if (bmapOrtho.getVisible() && !bmapGrau.getVisible()) {
    maptype = 'ortho';
  }
  document.querySelector('[for=' + maptype + ']').classList.add('selected');
}

// map_control - controll buttons
function layer_switch(obj, event) {
  if (
    typeof event != 'undefined' &&
    (event.target.classList.contains('opacity-slider') ||
      event.target.classList.contains('sliderbox'))
  ) {
    return;
  }
  popdown();

  let otype = '';
  if (obj.classList.contains('fachkarte')) {
    otype = 'fachkarte';
  }
  if (obj.classList.contains('checkbox')) {
    otype = 'checkbox';
  }
  if (obj.classList.contains('radio')) {
    otype = 'radio';
  }
  if (obj.classList.contains('layer-section-head')) {
    otype = 'section';
  }
  if (obj.classList.contains('mapbutton')) {
    otype = 'map';
  }

  /* Fachkarten */
  if (otype == 'fachkarte') {
    const karte = obj.getAttribute('for');
    setDataDomain(karte);
  }

  /* Radio Buttons (Layer Typen) */
  if (otype == 'radio') {
    if (obj.classList.contains('selected')) {
      return;
    }
    if (document.querySelector('.radio.selected')) {
      document.querySelector('.radio.selected').classList.remove('selected');
    }
    obj.classList.add('selected');
    const target = obj.getAttribute('target');
    if (target == 'erscheinung_jahr') {
      // Sonderbehandlung erscheinung_jahr!
      const view = map.getView();
      if (view.getZoom() > 10) {
        view.animate({
          zoom: 10,
        });
      }

      layers.kartierungsbereich_mpoly.type = 'erscheinung_jahr';
      bodenkarte.setVisible(false);
      layers.sonstigeflaechen_mpoly.visible = false;
      document
        .querySelector('.checkbox.sonstige.section1')
        .classList.add('greyout');
    } else {
      if (document.querySelector('.checkbox.sonstige.selected')) {
        layers.sonstigeflaechen_mpoly.visible = true;
      }
      document
        .querySelector('.checkbox.sonstige.section1')
        .classList.remove('greyout');
      const type = obj.getAttribute('target');
      const raster = config.topics[type].type === 'raster';
      bodenkarte.setVisible(!raster);
      rasterkarte.setVisible(raster);
      layers.bodenform_mpoly.type = raster ? undefined : type;
      layers.kartierungsbereich_mpoly.type = 'kb_id';
    }
  }

  /* Checkboxes (Layer Switcher) */
  if (otype == 'checkbox') {
    if (obj.classList.contains('greyout')) {
      return;
    }
    if (obj.classList.contains('selected')) {
      obj.classList.remove('selected');
      layers.sonstigeflaechen_mpoly.visible = false;
    } else {
      obj.classList.add('selected');
      layers.sonstigeflaechen_mpoly.visible = true;
    }
  }

  /* Layer Sections */
  if (otype == 'section') {
    const snr = obj.getAttribute('for');
    const sob = document.getElementById('layersection' + snr);
    if (sob.classList.contains('section-open')) {
      sob.classList.remove('section-open');
    } else {
      if (document.getElementsByClassName('section-open').length > 0) {
        document
          .getElementsByClassName('section-open')[0]
          .classList.remove('section-open');
      }
      sob.classList.add('section-open');
      if (!obj.classList.contains('noautoswitch')) {
        const switches = sob.getElementsByClassName('radio');
        let autoswitch = true;
        for (let s = 0; s < switches.length; s++) {
          if (switches[s].classList.contains('selected')) {
            autoswitch = false;
          }
        }
        if (autoswitch) {
          layer_switch(switches[0]);
        }
        if (document.querySelector('.sonstige.selected')) {
          document
            .querySelector('.sonstige.selected')
            .classList.remove('selected');
          sob.querySelector('.sonstige').classList.add('selected');
        }
      }
    }
  }

  /* Karten-Typ */
  if (otype == 'map') {
    let maptype = obj.getAttribute('for');
    document.querySelector('.mapbutton.selected').classList.remove('selected');
    let maptypeindex = mapmodes.indexOf(maptype) + 1;
    if (maptypeindex > 2) {
      maptypeindex = 0;
    }
    maptype = mapmodes[maptypeindex];
    document
      .querySelector('.mapbutton.for' + maptype)
      .classList.add('selected');
    switch (maptype) {
      case 'auto':
        bmapGrau.setMinResolution(bmapGrauMinRes);
        bmapGrau.setMaxResolution(bmapGrauMaxRes);
        bmapOrtho.setMinResolution(bmapOrthoMinRes);
        bmapOrtho.setMaxResolution(bmapOrthoMaxRes);
        bmapOrtho.setVisible(true);
        bmapGrau.setVisible(true);
        break;
      case 'topo':
        bmapGrau.setMinResolution(bmapOrthoMinRes);
        bmapGrau.setMaxResolution(bmapGrauMaxRes);
        bmapOrtho.setVisible(false);
        bmapGrau.setVisible(true);
        break;
      case 'ortho':
        bmapOrtho.setMinResolution(bmapOrthoMinRes);
        bmapOrtho.setMaxResolution(bmapGrauMaxRes);
        bmapGrau.setVisible(false);
        bmapOrtho.setVisible(true);
        break;
      default:
        break;
    }
  }
}

/* Opacity Slider */
function opacity_slider(oval, ev) {
  bodenkarte.setOpacity(oval / 100);
  rasterkarte.setOpacity(oval / 100);
  kartierungsbereich.setOpacity(oval / 100);
  const cbox = popuptarget.querySelector('.coloredbox');
  if (cbox) {
    cbox.style.opacity = oval / 100;
  }

  const oslist = document.querySelectorAll('.opacity-slider');
  for (let o = 0; o < oslist.length; o++) {
    oslist[o].value = oval;
  }
}
