/**
 * This module contains the styles for all vector and vector tile layers.
 * Exports refer either to a `layer` provided by a VectorTile source, or to a
 * Vector layer.
 *
 * All vector styles are defined here, except for the polygon highlight style
 * (see `../style.js`).
 *
 * The order of exports here defines the layer index within a
 * VectorTile layer, and in the debug layer switcher (`../debug.layerswitcher.js`).
 */

import config from '../data/config.json';

function sorter(a, b) {
  return a.reihenfolge - b.reihenfolge;
}
function toColor(legendEntry) {
  return `rgb(${legendEntry.r}, ${legendEntry.g}, ${legendEntry.b})`;
}
function reducer(obj, value) {
  obj['code' in value ? value.code : value.param] = toColor(value);
  return obj;
}

const bodenformLegends = {};
for (const key in config.legends) {
  if (key !== 'sonstigeflaechen') {
    bodenformLegends[key === 'humusform' ? 'humusart' : key] = config.legends[
      key
    ]
      .sort(sorter)
      .reduce(reducer, {_data: config.legends[key]});
  }
}

export const layers = {
  profil_point: {
    visible: true,
    switchable: false,
    type: 'prof7_id',
    icon: {
      src: 'profil_point',
      src_highlight: 'profil_point_highlight',
      size: [16, 16],
      size_highlight: [20, 20],
    },
  },
  kartierungsbereich_mpoly: {
    type: 'kb_id',
    visible: true,
    style: {
      kb_id: {
        getCategory: function (type) {
          return type == 215 || type == 216 || type == 219
            ? 'unkartiert'
            : 'kartiert';
        },
        category: {
          'unkartiert': 'rgba(100, 100, 100, 0.5)',
          'kartiert': {
            stroke: 'rgb(100, 100, 100)',
            width: 1,
            text: {
              field: (resolution) =>
                resolution < 305.7481131407055 ? 'name' : 'kb_id',
              fill: 'rgb(100, 100, 100)',
              font: 'bold 13px sans-serif',
            },
          },
        },
      },
      erscheinung_jahr: {
        getCategory: function (type) {
          return !type
            ? 'nicht kartiert'
            : type <= 1979
            ? 'bis 1979'
            : type <= 1989
            ? '1980 bis 1989'
            : type <= 1999
            ? '1990 bis 1999'
            : '2000 bis jetzt';
        },
        category: {
          'nicht kartiert': {fill: 'white', stroke: 'black'},
          'bis 1979': {fill: 'rgb(240, 216, 255)', stroke: 'black'},
          '1980 bis 1989': {fill: 'rgb(254, 215, 210)', stroke: 'black'},
          '1990 bis 1999': {fill: 'rgb(254, 244, 208)', stroke: 'black'},
          '2000 bis jetzt': {fill: 'rgb(240, 255, 213)', stroke: 'black'},
        },
      },
    },
  },
  sonstigeflaechen_mpoly: {
    type: 'code',
    visible: true,
    style: {
      code: config.legends.sonstigeflaechen
        .sort(sorter)
        .reduce(reducer, {_data: config.legends.sonstigeflaechen}),
    },
  },
  bodenform_mpoly: {
    type: 'bofo_id',
    visible: true,
    style: Object.assign(bodenformLegends, {
      bofo_id: {
        getCategory: function () {
          return '';
        },
        category: {
          '': {
            stroke: 'red',
            width: 2,
          },
        },
      },
    }),
  },
  erosionskarte: {
    type: 'erosionskarte',
    visible: false,
  },
};

export function syncLayers(map) {
  map.getLayers().forEach((layer) => {
    const layerName = layer.get('name');
    const isArray = Array.isArray(layerName);
    const layerNames = isArray ? layerName : [layerName];
    for (let i = 0, ii = layerNames.length; i < ii; ++i) {
      const layerName = layerNames[i];
      if (layerName && layerName in layers) {
        const data = layers[layerName];
        let visible = data.visible;
        Object.defineProperty(data, 'visible', {
          set: function (value) {
            visible = value;
            if (isArray) {
              layer.changed();
            } else {
              layer.setVisible(value);
            }
            const bound = layer.get('bound');
            if (bound && layerName in bound) {
              bound[layerName].setVisible(value);
            }
          },
          get: function () {
            return visible;
          },
        });
        let type = data.type;
        Object.defineProperty(data, 'type', {
          set: function (value) {
            type = value;
            layer.changed();
          },
          get: function () {
            return type;
          },
        });
      }
    }
  });
}

export const layerNames = {
  code: 'Sonstige Flächen',
};

for (const key in config.topics) {
  layerNames[key] = config.topics[key].title;
}
