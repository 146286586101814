/**
 * @typedef columnSize
 * @property {Array<number>} letters number of max Letters in this column
 * @property {Array<number>} columnSize size of each column in mm
 */

/**
 * calculate the amount of letters and the size per column (of 9 items)
 * from an array of content strings
 * @param {Array<string>} labels array of labels
 * @param {Object} pdf jspdf document
 * @param {number} textBuffer buffer in mm (for padding and legend icon)
 * @param {number} textSize size of text in pt
 * @param {number} headerSize length of head in mm
 * @return {columnSize} columnSize-Object with amount of column
 */
export function calculateColumnSizes(
  labels,
  pdf,
  textBuffer,
  textSize,
  headerSize
) {
  let maxWidth = 0;
  const columnSizes = []; // array of max space per column (in mm);
  for (let i = 0, ii = labels.length; i < ii; i++) {
    const textWidth =
      (pdf.getStringUnitWidth(labels[i].textContent, {fontSize: textSize}) *
        textSize) /
        (72 / 25.6) +
      textBuffer;
    if (textWidth > maxWidth) {
      maxWidth = textWidth;
    }
    if ((i > 0 && (i + 1) % 9 === 0) || i === labels.length - 1) {
      // if this is the last element of the column or the very last element
      columnSizes.push(maxWidth);
      maxWidth = 0;
    }
    if (i === 36) {
      break; // maximum columns
    }
  }

  const space = 180; // max space in mm from left border (minus padding) to min-Size of "sonstige flaechen"
  // make at least 4 columns fit. Shorten the columns if needed

  let columnsFit = false;
  while (!columnsFit) {
    const fullSize = columnSizes.reduce((accumulator, currentValue) => {
      return accumulator + currentValue;
    });
    if (fullSize > space) {
      // if the current layout does not fit, try to shorten the longest column
      const indexOfLargestColumn = columnSizes.indexOf(
        Math.max(...columnSizes)
      );
      columnSizes[indexOfLargestColumn] =
        columnSizes[indexOfLargestColumn] * 0.7;
    } else {
      columnsFit = true;
    }
  }

  const fullSize = columnSizes.reduce((accumulator, currentValue) => {
    return accumulator + currentValue;
  });
  if (headerSize > fullSize) {
    const difference = headerSize - fullSize;
    columnSizes[columnSizes.length - 1] =
      columnSizes[columnSizes.length - 1] + difference;
  }

  return {
    letters: columnSizes,
    size: columnSizes.map((size) => size - textBuffer), // remove textBuffer again
  };
}
