import './measure.css';
import Draw from 'ol/interaction/Draw.js';
import Feature from 'ol/Feature';
import Overlay from 'ol/Overlay.js';
import {Circle, Fill, Stroke, Style, Text} from 'ol/style.js';
import {LineString, Polygon} from 'ol/geom.js';
import {Vector as VectorLayer} from 'ol/layer.js';
import {Vector as VectorSource} from 'ol/source.js';
import {getArea, getLength} from 'ol/sphere.js';
import {getTempLayer, tempLayer} from './maplayers.js';
import {removeTextOverlays} from './textinteraction.js';
import {stopSketching} from './sketch.js';
import {unByKey} from 'ol/Observable.js';

/**
 * draw interaction for measuring
 **/
let measureDraw;

/**
 * change event listener for calculating geometry size
 * @param {event} e pointermove event
 **/
const geomChangeListener = function (e) {
  const geom = e.target.getGeometry();
  let output;
  let tooltipCoord;
  if (geom instanceof Polygon) {
    output = formatArea(geom);
    tooltipCoord = geom.getInteriorPoint();
  } else if (geom instanceof LineString) {
    output = formatLength(geom);
    tooltipCoord = geom; // get actual feature?
  }
  measure.label.set('text', output);
  measure.label.setGeometry(tooltipCoord);
};

/**
 * removes measure interaction
 **/
export function stopMeasure() {
  unByKey(geomChangeListener);
  //removeSketch();
  measure.map.removeInteraction(measureDraw);
  measure.map.removeInteraction(measure.tooltipChangeHandler);
  if (measure.helpTooltip) {
    measure.helpTooltip.setPosition(undefined);
    measure.helpTooltip.setMap(null);
  }
}

/**
 * create and style a help tooltip as ol overlay
 @param {Object} app measure or sketch
 @param {module:ol/CanvasMap~CanvasMap} map The map to create the tooltips for
 */
export function createHelpTooltip(app, map) {
  app.helpTooltipElement = document.createElement('div');
  app.helpTooltipElement.className = 'tooltip hidden';
  app.helpTooltip = new Overlay({
    element: app.helpTooltipElement,
    offset: [15, 0],
    positioning: 'center-left',
  });
  app.helpTooltip.setMap(map);

  map.getViewport().addEventListener('mouseout', function () {
    app.helpTooltipElement.classList.add('hidden');
  });
  map.getViewport().addEventListener('mouseout', function () {
    app.helpTooltipElement.classList.add('hidden');
  });
}

/**
 * initialize measure tool.
 * @param {module:ol/CanvasMap~CanvasMap} map The map to create the measure interaction for.
 * @return {Function} Click handler function
 */
export function measure(map) {
  measure.map = map;

  initMeasure();

  /**
   * click handler, starts measure interaction
   * @param {event} event click event, reads data-type of cicked button for geometry type
   */
  function clickHandler(event) {
    if (event.target.classList.contains('active')) {
      stopSketching(map);
      removeTextOverlays();
      stopMeasure();
      // start measuring
      addInteraction(event.target.getAttribute('data-type'));
    } else {
      stopMeasure();
    }
  }

  /**
   * initializes measure interaction, provides vector layer, styles and draw interaction
   */
  function initMeasure() {
    measure.source = new VectorSource();

    const defaultStyle = new Style({
      fill: new Fill({
        color: 'rgba(255, 255, 255, 0.3)',
      }),
      stroke: new Stroke({
        color: 'rgba(0, 0, 0, 0.5)',
        lineDash: [10, 10],
        width: 2,
      }),
      text: new Text({
        text: '',
        font: '16px Open Sans,sans-serif',
        fill: new Fill({
          color: '#000',
        }),
        offsetY: -7,
        padding: [5, 5, 5, 5],
        stroke: new Stroke({
          color: '#fff',
          width: 3,
        }),
      }),
    });

    if (getTempLayer('measure')) {
      measure.measureLayer = getTempLayer('measure');
      getTempLayer('measure').setMap(measure.map);
    } else {
      measure.measureLayer = new VectorLayer({
        source: measure.source,
        style: function (feature, resolution) {
          defaultStyle.getText().setText(feature.get('text'));
          return defaultStyle;
        },
        name: 'measure',
      });
    }
    tempLayer.push(measure.measureLayer);
  }

  /**
   * add measure interaction of a chosen type
   * @param {string} type type of draw interaction ('Polygon' or 'LineString');
   */
  function addInteraction(type) {
    measure.tooltipChangeHandler = measure.map.on(
      'pointermove',
      pointerMoveHandler
    );

    measureDraw = new Draw({
      stopClick: true,
      source: measure.source,
      type: type,
      style: new Style({
        fill: new Fill({
          color: 'rgba(255, 255, 255, 0.3)',
        }),
        stroke: new Stroke({
          color: 'rgba(0, 0, 0, 0.5)',
          lineDash: [10, 10],
          width: 2,
        }),
        image: new Circle({
          radius: 5,
          stroke: new Stroke({
            color: 'rgba(0, 0, 0, 0.7)',
          }),
          fill: new Fill({
            color: 'rgba(255, 255, 255, 0.2)',
          }),
        }),
      }),
    });
    measure.map.addInteraction(measureDraw);
    //measure.measureLayer.setMap(measure.map);

    createHelpTooltip(measure, map);

    measureDraw.on('drawstart', function (evt) {
      // set map on drawstart, if user deletes layer while measuring
      measure.measureLayer.setMap(measure.map);

      // define helpMsg here also, because right after first click there is no pointermove
      measure.helpTooltipElement.innerHTML = 'Fortsetzen mit Klick';

      // set sketch
      measure.sketch = evt.feature;

      //set label for showing the measurement
      measure.label = new Feature({
        text: '',
      });

      // listener to handle area output while drawing
      measure.sketch.on('change', geomChangeListener);

      measure.source.addFeature(measure.label);
    });

    measureDraw.on('drawend', function () {
      // unset sketch
      measure.sketch.un('change', geomChangeListener);
      measure.sketch = null;
      measure.helpTooltipElement.innerHTML = 'Beginnen mit Klick';
    });
  }

  /**
   * handles tooltip behaviour
   * @param {event} evt pointermove event
   */
  function pointerMoveHandler(evt) {
    if (evt.dragging) {
      return;
    }

    let helpMsg = 'Beginnen mit Klick';

    if (measure.sketch) {
      helpMsg = 'Fortsetzen mit Klick, beenden mit 2x Klick';
      const geom = measure.sketch.getGeometry();
      if (geom instanceof Polygon && geom.getCoordinates()[0].length < 4) {
        helpMsg = 'Fortsetzen mit Klick';
      }
    }

    measure.helpTooltipElement.innerHTML = helpMsg;
    measure.helpTooltip.setPosition(evt.coordinate);

    measure.helpTooltipElement.classList.remove('hidden');
  }
  return clickHandler;
}

/**
 * calculate and format length of lineDash
 * @param {module:ol/geom/LineString~LineString} line line geometry
 * @return {string} length of line, formated as string
 */
function formatLength(line) {
  const length = getLength(line);
  let output;
  if (length > 1000) {
    output = Math.round((length / 1000) * 100) / 100 + ' ' + 'km';
  } else {
    output = Math.round(length * 100) / 100 + ' ' + 'm';
  }
  return output;
}

/**
 * calculate and format area of polygon
 * @param {module:ol/geom/Polygon~Polygon} polygon polygon geometry
 * @return {string} area of polygon, formated as string
 */
function formatArea(polygon) {
  const area = getArea(polygon);
  let output;
  if (area > 100000) {
    output =
      Math.round((area / 1000000) * 100) / 100 +
      ' km' +
      String.fromCharCode(178);
  } else {
    output = Math.round(area * 100) / 100 + ' m' + String.fromCharCode(178);
  }
  return output;
}
