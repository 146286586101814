import GeoJSON from 'ol/format/GeoJSON.js';
import {Vector as VectorSource} from 'ol/source.js';
import {getTempLayer} from './maplayers.js';
import {inAndOut} from 'ol/easing.js';
import {labelStylefunction} from './style.js';

/**
 * initialize button for GeoJSON import
 * @param {module:ol/CanvasMap~CanvasMap} map The map the GeoJSON is going to be imported to.
 * @return {Function} Click handler function
 */
export function initJsonImport(map) {
  return function (evt) {
    const hiddenButton = document.createElement('input');
    hiddenButton.type = 'file';
    hiddenButton.addEventListener(
      'change',
      (evt) => {
        const files = evt.target.files;
        const file = files[0];
        const reader = new FileReader();

        reader.onload = (theFile) => {
          const geoJSON = JSON.parse(theFile.currentTarget.result);
          addLayer(map, geoJSON);
        };

        reader.readAsText(file);
      },
      false
    );
    hiddenButton.click();
  };
}

function addLayer(map, geoJSON) {
  const features = new GeoJSON().readFeatures(geoJSON, {
    dataProjection: 'EPSG:4326',
    featureProjection: 'EPSG:3857',
  });

  // style label points differently
  for (let i = 0; i < features.length; i++) {
    if (
      features[i].getGeometry().getType() === 'Point' &&
      features[i].get('text')
    ) {
      features[i].setStyle(labelStylefunction);
    }
  }

  const sketchLayer = getTempLayer('sketch');
  sketchLayer.getSource().addFeatures(features);
  sketchLayer.setMap(map);

  //create temporary source, only to get extent for zooming to the newest added features
  const extentSource = new VectorSource({
    features: features,
  });
  const view = map.getView();
  view.fit(extentSource.getExtent(), {
    size: map.getSize(),
    duration: 800,
    easing: inAndOut,
  });
}
