/**
 * This module contains all controll routines for handling of the Layer Switcher
 * and tool box buttons
 */

import './mapcontrols.css';
import MousePosition from 'ol/control/MousePosition';
import markup from './mapcontrols.thtml';
import markuptbh from './toolboxhelp.thtml';
import {ScaleLine} from 'ol/control.js';
import {format} from 'ol/coordinate.js';
import {hideonopen} from './navigation.js';
import {map} from './map.js';
import {popitup} from './popup.js';
import {stopSketching} from './sketch.js';

// map_control - controll buttons
export function map_control(obj, mode) {
  const oname = obj.getAttribute('for');
  if (mode == 'switch') {
    if (oname == 'contentpage') {
      document.getElementById(oname).setAttribute('current', '');
    }
    if (document.getElementById(oname).classList.contains('slideup' + oname)) {
      document.querySelector('.grayout').classList.add('hideme');
      document.getElementById(oname).classList.add('slidedown' + oname);
      document.getElementById(oname).classList.remove('slideup' + oname);
      if (oname == 'toolmenu') {
        stopSketching(map);
      }
    } else {
      if (oname != 'legendcontainer') {
        hideonopen();
        document.getElementById(oname).classList.add('slideup' + oname);
      } else {
        document
          .getElementById(oname)
          .classList.add('slideup' + oname + 'action');
      }
      document.getElementById(oname).classList.remove('slidedown' + oname);
    }
  }
}

function toolbox_help() {
  const toolm = document.querySelector('#toolmenu');
  const dimensions = {
    'width': toolm.clientWidth,
    'height': Math.min(450, document.querySelector('#map').clientHeight - 10),
  };
  const position = [
    toolm.offsetLeft,
    document.querySelector('#map').clientHeight - dimensions.height - 10,
  ];

  popitup(
    'Toolbox-Funktionen',
    markuptbh({}),
    dimensions,
    map,
    null,
    position,
    true,
    true
  );
}
/* MAP CONTROL INIT */
export function initMapControls(container) {
  const controls = document.createElement('div');
  controls.innerHTML = markup({});
  container.appendChild(controls);

  const elist = document.querySelectorAll('.switchbutton');
  for (let e = 0; e < elist.length; e++) {
    elist[e].addEventListener('click', function (event) {
      map_control(this, 'switch');
    });
  }

  document
    .querySelector('.helpbutton.toolbox')
    .addEventListener('click', function () {
      toolbox_help();
    });
}

/* Warning box if Zoom > 16 */
export function zoomWarning(onoff) {
  if (onoff) {
    document.querySelector('#zoomwarning').classList.remove('hidden');
  } else {
    document.querySelector('#zoomwarning').classList.add('hidden');
  }
}

export const MousePositionControl = new MousePosition({
  className: 'coordinates',
  coordinateFormat: (coordinate) => {
    const template = '{y}°N {x}°E';
    return format(coordinate, template, 4);
  },
  projection: 'EPSG:4326',
});

export const ScaleLineControl = new ScaleLine({
  minWidth: '100',
  units: 'metric',
});
