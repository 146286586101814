import Feature from 'ol/Feature';
import Overlay from 'ol/Overlay';
import Point from 'ol/geom/Point';
import {Interaction} from 'ol/interaction.js';
import {labelStylefunction} from './style.js';
import {tooltipHolder} from './sketch.js';

/**
 * array containing all sketch overlays, like unfinished textboxes
 **/
const textOverlays = [];

/**
 * removes all (unfinished) text overlays from the map
 */
export function removeTextOverlays() {
  const length = textOverlays.length;
  for (let i = length - 1; i >= 0; i--) {
    textOverlays[i].setPosition(undefined);
    textOverlays[i].setMap(null);
  }
  textOverlays.length = 0;
}

/**
 * @class
 * @param {module:ol/CanvasMap~CanvasMap} map map
 * @param {ol/layer/Vector~VectorLayer} sketchLayer layer for creating or changing text
 * @extends {module:ol/interaction/Interaction}
 */
class TextInteraction extends Interaction {
  constructor(map, sketchLayer) {
    super({
      handleEvent: TextInteraction.prototype.handleEvent,
    });
    this.map = map;
    this.sketchLayer = sketchLayer;
  }
}

TextInteraction.prototype.handleEvent = function (evt) {
  if (evt.type !== 'click') {
    return true;
  }
  const featuresFound = this.checkForText(evt);
  // return false prevents other click event like standard popup
  // if no features are found, and this interaction is called for
  // an imported GeoJSON, allow standard popup to be created
  if (!featuresFound && this.sketchLayer.get('name') === 'importedGeoJSON') {
    return true;
  }
  return false;
};
// at the down event, check if text exists.
TextInteraction.prototype.checkForText = function (evt) {
  //const map = this.map;
  this.editTextFeature = null;
  // forEachFeatureAtPixel,... feature ist erstes feature mit Text
  const currentSketchLayer = this.sketchLayer;
  this.map.forEachFeatureAtPixel(
    evt.pixel,
    (feature, layer) => {
      if (feature.get('text')) {
        this.editTextFeature = feature;
      }
    },
    {
      layerFilter: function (layer) {
        return !!(layer.get('name') === currentSketchLayer.get('name'));
      },
    }
  );
  this.addTextbox(evt.coordinate);
  return !!this.editTextFeature;
};

TextInteraction.prototype.handleDragEvent = function (evt) {
  return;
};

TextInteraction.prototype.handleMoveEvent = function (evt) {
  return;
};

/**
 * creates a popup for text-input from user.
 * @param {module:ol/coordinate~Coordinate} coordinate coordinate as position of overlay
 */
TextInteraction.prototype.addTextbox = function (coordinate) {
  tooltipHolder.feature = true;
  removeTextOverlays(this.map);
  // don't create new feature when editing text
  const target = document.createElement('div');
  target.classList.add('textPopup');
  // create textBox
  this.textBox = document.createElement('textarea');
  this.textBox.classList.add('textBox');
  target.appendChild(this.textBox);
  // create overlay
  const overlay = new Overlay({
    element: target,
    positioning: 'top-laft',
    autoPan: true,
    offset: [6, 2],
  });
  textOverlays.push(overlay);
  overlay.setMap(this.map);

  // if feature has text, start with existing text in textBox
  if (this.editTextFeature) {
    const text = this.editTextFeature.get('text');
    this.textBox.value = text;
    coordinate = this.editTextFeature.getGeometry().getCoordinates();
    //this.editTextFeature = null;
  } else {
    // don't create new points for imported GeoJSON, only modify
    if (this.sketchLayer.get('name') === 'importedGeoJSON') {
      return;
    }
    //coordinate = e.coordinate; //?
  }
  overlay.setPosition(coordinate);
  const view = this.map.getView();
  view.animate({
    center: coordinate,
  });
  const options = {
    textBox: this.textBox,
    coordinate: coordinate,
    overlay: overlay,
    feature: this.editTextFeature,
    sketchLayer: this.sketchLayer,
    map: this.map,
  };
  // keypress doesn't recognize backspace
  this.textBox.addEventListener('keydown', this.applyText.bind(options));
  this.textBox.focus();
  const pixel = this.map.getPixelFromCoordinate(coordinate);
  pixel[1] -= 20; //add some pixel to place tooltip above textbox
  tooltipHolder.helpTooltip.setPosition(this.map.getCoordinateFromPixel(pixel));
  tooltipHolder.helpTooltipElement.innerHTML = 'Texteingabe';
};

/**
 * create or update point feature with text from corresponding text area
 * *this* is an object, containing the properties:
 * textBox
 * coordinate
 * Overlay
 * feature
 * sketchLayer
 * map
 * @param {event} evt click event, must be fired from the textbox
 * @return {boolean|undefined} true if pressed key is not enter
 */
TextInteraction.prototype.applyText = function (evt) {
  const key = evt.keyCode;
  // get text of textBox
  let text = this.textBox.value;
  const coordinates = this.coordinate;
  // try to get stored ID of feature, if none exists, create new one and store new ID
  if (this.feature) {
    //const feature = this.sketchLayer.getSource().getFeatureById(this.featureID);
    //const feature = this.editTextFeature;
    // if backspace, take away the last digit,
    //because keydown is called before actual change of textbox
    if (key === 8) {
      text = text.substring(0, text.length - 1);
    }
    this.feature.set('text', text);
    if (key !== 13) {
      const helpMsg =
        text.length === 0 ? 'Texteingabe' : 'Beenden mit Return-Taste';
      tooltipHolder.helpTooltipElement.innerHTML = helpMsg;
      return true;
    }
  } else {
    if (key !== 13) {
      // If the user has NOT pressed enter
      tooltipHolder.helpTooltipElement.innerHTML = 'Beenden mit Return-Taste';
      return true;
    }
    const feature = new Feature({
      geometry: new Point(coordinates),
    });
    feature.set('text', text);
    const newFeatureID = Date.now().toString(); // other way?
    feature.setId(newFeatureID);
    feature.setStyle(labelStylefunction);
    this.sketchLayer.getSource().addFeature(feature);
    this.sketchLayer.setMap(this.map);
    tooltipHolder.feature = false;
    tooltipHolder.helpTooltipElement.innerHTML = 'Beginnen mit Klick';
  }
  // unset temporary overlays
  this.overlay.setMap(null);
  this.overlay.setPosition(undefined);
  this.textBox = null;
  removeTextOverlays(this.map);
};

export default TextInteraction;
