import './legend.css';
import * as render from 'ol/render';
import Draggable from 'draggable';
import Icon from 'ol/style/Icon';
import LineString from 'ol/geom/LineString';
import Point from 'ol/geom/Point';
import Polygon from 'ol/geom/Polygon';
import Style from 'ol/style/Style';
import config from '../data/config.json';
import debounce from 'debounce';
import markup from './legend.thtml';
import {Fill} from 'ol/style';
import {GeoTIFF} from 'ol/source';
import {inView} from 'ol/layer/Layer';
import {layerNames, layers as layerStyles} from './layers.js';

const legenden = {};
for (const key in config.legends) {
  legenden[key === 'humusform' ? 'humusart' : key] = {
    'type': Object.keys(config.legends[key][0])[0], // 'param' or 'code'
    'content': config.legends[key],
  };
}

export const legendenHash = {};
export const legendItems = {
  headingText: '',
  layerDivs: [],
  sonstigeFlaechen: false,
  sonstigeFlaechenDivs: [],
}; // legendItems for recreation of legend in pdf

for (const leg in legenden) {
  legendenHash[leg] = [];
  for (let lc = 0; lc < legenden[leg]['content'].length; lc++) {
    if (leg == 'sonstigeflaechen') {
      legendenHash[leg][legenden[leg]['content'][lc][legenden[leg]['type']]] = {
        'legende': legenden[leg]['content'][lc].bezeichnung,
        'ord': legenden[leg]['content'][lc].reihenfolge,
        'color':
          'rgb(' +
          legenden[leg]['content'][lc].r +
          ',' +
          legenden[leg]['content'][lc].g +
          ',' +
          legenden[leg]['content'][lc].b +
          ')',
      };
    } else {
      legendenHash[leg][legenden[leg]['content'][lc][legenden[leg]['type']]] = {
        'legende': legenden[leg]['content'][lc].legende,
        'ord': legenden[leg]['content'][lc].reihenfolge,
        'color':
          'rgb(' +
          legenden[leg]['content'][lc].r +
          ',' +
          legenden[leg]['content'][lc].g +
          ',' +
          legenden[leg]['content'][lc].b +
          ')',
      };
    }
  }
}

const legendSections = {
  bodenform_mpoly: [],
  sonstigeflaechen_mpoly: [],
  kartierungsbereich_mpoly: [],
  erosionskarte: [],
};

const rasterStyle = new Style({
  fill: new Fill(),
});

/**
 * Creates a dynamic legend and populates the `target` with it.
 * @param {Element} container The container to populate with the legend
 * @param {module:ol/Map~Map} map The map to create the legend for.
 */
export function initLegend(container, map) {
  // Init Resizable Controls!
  const lrcontrol = document.querySelector('.legendresize');
  container.addEventListener(
    'animationstart',
    function () {
      if (this.classList.contains('slidedownlegendcontainer')) {
        lrcontrol.classList.add('hidden');
      }
    },
    false
  );
  container.addEventListener(
    'animationend',
    function () {
      if (this.classList.contains('slideuplegendcontaineraction')) {
        this.classList.remove('slideuplegendcontaineraction');
        this.classList.add('slideuplegendcontainer');
        lrcontrol.classList.remove('hidden');
      }
    },
    false
  );

  const mapw = document.querySelector('.map').clientWidth;
  const maph = document.querySelector('.map').clientHeight;

  let lminy = 214;
  let lmaxy = maph - 160;
  if (maph <= 660 || mapw <= 900) {
    lminy = 164;
    lmaxy = maph - 125;
  }

  const dragopts = {
    'limit': {
      x: 10,
      y: [lminy, lmaxy],
    },
    'setPosition': false,
    onDrag: function (elm) {
      document.querySelector('.legendouter').style.height =
        elm.offsetTop + 6 + 'px';
      //console.log(elm.offsetTop); //eslint-disable-line
    },
  };

  new Draggable(document.querySelector('.legendresize'), dragopts);

  // Go on...
  container.innerHTML = markup({});
  const target = document.createElement('div');
  target.className = 'legendinner';
  target.id = 'legend';
  container.appendChild(target);

  function updateLegend() {
    const view = map.getView();
    const resolution = view.getResolution();

    if (!view.getAnimating() && !view.getInteracting()) {
      const extent = view.calculateExtent();
      const layers = map.getLayers().getArray();
      const completeLegendDiv = document.createElement('div');
      let pointRowDiv = null;
      legendSections.bodenform_mpoly.length = 0;
      legendSections.sonstigeflaechen_mpoly.length = 0;
      legendSections.kartierungsbereich_mpoly.length = 0;
      legendSections.erosionskarte.length = 0;
      completeLegendDiv.id = 'completeLegend';

      for (const layerStyle in layerStyles) {
        // find layer connected to current layerStyle
        for (let i = 0, ii = layers.length; i < ii; ++i) {
          const layer = layers[i];
          // abort if layer is not visible
          if (inView(layer.getLayerState(), view.getState()) === false) {
            continue;
          }
          let name = layer.get('name');
          if (Array.isArray(name)) {
            name = name.indexOf(layerStyle) !== -1 ? layerStyle : '';
          }
          if (name !== layerStyle) {
            continue;
          }

          //check if features are in extent
          const source = layer.getSource();
          if (source instanceof GeoTIFF) {
            legendSections[layerStyle] = config.legends[name].map((l) => {
              rasterStyle.getFill().setColor([l.r, l.g, l.b]);
              return createLegendRow(
                null,
                layer,
                undefined,
                undefined,
                l.legende,
                layerStyle
              );
            });
            continue;
          }
          const featuresFound = {};
          const styleName = layerStyles[name].type;

          let labelText;
          const features = source.getFeaturesInExtent(extent);

          for (let i = 0, ii = features.length; i < ii; ++i) {
            const feature = features[i];
            let featureStyleName = feature.get(styleName);

            // if profil_point or kartierungsbereich_mpoly
            if (feature.getGeometry().getType() === 'Point') {
              featureStyleName = styleName;
              labelText = 'Profilstelle';
            } else if (
              layerStyles[name].style[styleName].getCategory !== undefined
            ) {
              featureStyleName =
                layerStyles[name].style[styleName].getCategory(
                  featureStyleName
                );
              labelText = featureStyleName;
            }
            // if feature is already in legend
            if (featureStyleName in featuresFound) {
              continue;
            }

            const legendRow = createLegendRow(
              feature,
              layer,
              resolution,
              styleName,
              labelText,
              layerStyle
            );

            if (legendRow) {
              if (layerStyle == 'profil_point') {
                pointRowDiv = legendRow;
              } else if (layerStyles[layerStyle].type !== 'kb_id') {
                legendSections[layerStyle].push(legendRow);
              }
              featuresFound[featureStyleName] = true;
            }
          }

          // stop looking for additional layers if correct layer was found
          break;
        }
      }

      // Compile legend (including sort!)
      legendItems.sonstigeFlaechen = false;
      legendItems.sonstigeFlaechenDivs.length = 0;
      legendItems.layerDivs.length = 0;
      for (const key in legendSections) {
        const legendSection = legendSections[key];
        if (legendSection.length > 0) {
          legendSection.sort(legendSorter);
          const para = document.createElement('a');
          para.classList.add('layerHeading');
          const headingText = layerNames[layerStyles[key].type] + ':';
          para.appendChild(document.createTextNode(headingText));
          completeLegendDiv.appendChild(para);
          for (let l = 0; l < legendSection.length; l++) {
            completeLegendDiv.appendChild(legendSection[l]);
            if (headingText === 'Sonstige Flächen:') {
              legendItems.sonstigeFlaechen = true;
              legendItems.sonstigeFlaechenDivs.push(legendSection[l]);
            } else {
              legendItems.headingText = headingText;
              //debugger
              legendItems.layerDivs.push(legendSection[l]);
            }
          }
        }
        if (key === 'bodenform_mpoly' && pointRowDiv) {
          completeLegendDiv.appendChild(pointRowDiv);
          legendItems.layerDivs.push(pointRowDiv); // profistelle
        }
      }
      target.innerHTML = '';
      target.appendChild(completeLegendDiv);
    }
  }

  map.on('postcompose', debounce(updateLegend, 250));
}

/**
 * creates a div containing a single legend row for given
 * feature and resolution
 * @param {module:ol/Feature~Feature} feature Feature.
 * @param {module:ol/Layer~Layer} layer Layer.
 * @param {number} resolution Resolution.
 * @param {string} styleName Name of 'sublayer' for labeling.
 * @param {string} labelText Text to be displayed next to the legend symbol.
 * @param {string} layerStyle Parent layer style
 * @return {Element|undefined} legend row div
 */
function createLegendRow(
  feature,
  layer,
  resolution,
  styleName,
  labelText,
  layerStyle
) {
  if (
    (feature && typeof feature.get(styleName) === 'undefined') ||
    styleName === ''
  ) {
    return;
  }
  const featureStyle = feature
    ? layer.getStyleFunction()(feature, resolution)
    : [rasterStyle];
  //create div for legend graphic and layer layerName
  const legendRow = document.createElement('div');
  legendRow.classList.add('legendRow');
  //create canvas for found feature
  const canvas = document.createElement('canvas');
  canvas.style.opacity = layer.getOpacity();
  canvas.classList.add('legendGraphic');
  const vectorContext = render.toContext(canvas.getContext('2d'), {
    size: [18, 18],
  });
  const geometryType = feature ? feature.getGeometry().getType() : 'Polygon';

  if (featureStyle !== undefined) {
    // prevent highlighted style
    // length is 1 at profil_point, featureStyle[2] is pattern when selected
    vectorContext.setStyle(
      featureStyle.length === 1 ? featureStyle[0] : featureStyle[1]
    );
    if (geometryType === 'Polygon' || geometryType === 'MultiPolygon') {
      vectorContext.drawGeometry(
        new Polygon([
          [
            [2, 2],
            [16, 2],
            [16, 16],
            [2, 16],
            [2, 2],
          ],
        ])
      );
    } else if (geometryType === 'LineString') {
      vectorContext.drawGeometry(
        new LineString([
          [2, 2],
          [2, 16],
          [8, 2],
          [16, 16],
        ])
      );
    } else {
      //profil_point
      // better solution via getIcon from style.js?
      vectorContext.setStyle(
        new Style({
          image: new Icon({
            src: 'data/profil_point.svg',
            size: [16, 16],
          }),
        })
      );
      vectorContext.drawGeometry(new Point([8, 8]));
    }
    legendRow.appendChild(canvas);

    const para = document.createElement('a');
    const lsort = document.createAttribute('sort');
    lsort.value = 0;
    para.classList.add('legendLabel');

    const legendID = layerStyles[layerStyle].type;
    let completeLabelText = '';

    switch (layerStyle) {
      case 'kartierungsbereich_mpoly':
      case 'erosionskarte': {
        completeLabelText = labelText;
        lsort.value = feature ? feature.get(styleName) : 999999999;
        break;
      }
      case 'profil_point': {
        completeLabelText = 'Profilstelle';
        lsort.value = 999999999;
        break;
      }
      case 'bodenform_mpoly': {
        if (legendID == 'bofo_id') {
          completeLabelText = 'Bodenform';
        } else {
          completeLabelText =
            legendenHash[legendID][feature.get(styleName)].legende;
          lsort.value = legendenHash[legendID][feature.get(styleName)].ord;
        }
        break;
      }
      case 'sonstigeflaechen_mpoly': {
        completeLabelText =
          legendenHash['sonstigeflaechen'][feature.get(styleName)].legende;
        lsort.value =
          legendenHash['sonstigeflaechen'][feature.get(styleName)].ord;
        break;
      }
      default: {
        break;
      }
    }

    const node = document.createTextNode(completeLabelText);
    para.appendChild(node);
    legendRow.setAttributeNode(lsort);
    legendRow.appendChild(para);
    return legendRow;
  }
}

/* legendSorter - sorts the legendRow divs */
function legendSorter(a, b) {
  const as = Number(a.getAttribute('sort'));
  const bs = Number(b.getAttribute('sort'));
  let ret = 0;
  if (as > bs) {
    ret = 1;
  }
  if (as < bs) {
    ret = -1;
  }
  return ret;
}
