import './sketch.css';
import TextInteraction, {removeTextOverlays} from './textinteraction.js';
import {Draw, Modify, Snap} from 'ol/interaction.js';
import {Vector as VectorLayer} from 'ol/layer.js';
import {Vector as VectorSource} from 'ol/source.js';
import {createHelpTooltip, stopMeasure} from './measure.js';
import {sketchStyleFunction} from './style.js';
import {tempLayer} from './maplayers.js';

/**
 * instance of TextInteraction for newly written text on map
 **/
export let addTextForSketch;

/**
 * interaction to snap sketches to itself
 **/
let snap;

const source = new VectorSource();

const sketchLayer = new VectorLayer({
  source: source,
  style: sketchStyleFunction,
  name: 'sketch',
  renderBuffer: 500,
});

// don't use the normal source for modifying to prevent
// features (especially text) to stick together forever
const modifySource = new VectorSource();
const modify = initModify(modifySource);

// object for the tooltip messages
export const tooltipHolder = {};

/**
 * draw interaction for sketching lines
 **/
const drawLine = new Draw({
  stopClick: true, //true breaks modify?
  source: source,
  type: 'LineString',
  style: sketchStyleFunction,
});

drawLine.on(
  'drawstart',
  function (evt) {
    sketchLayer.setMap(evt.target.getMap());
    modify.setActive(false);
    tooltipHolder.helpTooltipElement.innerHTML =
      'Fortsetzen mit Klick, beenden mit 2x Klick';
    tooltipHolder.feature = true;
  },
  this
);

drawLine.on(
  'drawend',
  function () {
    modify.setActive(true);
    tooltipHolder.helpTooltipElement.innerHTML = 'Beginnen mit Klick';
    tooltipHolder.feature = false;
  },
  this
);

function initModify(modifySource) {
  const modify = new Modify({
    source: modifySource,
  });
  modify.on('modifystart', (e) => {
    removeTextOverlays();
    tooltipHolder.helpTooltipElement.classList.add('hidden');
  });
  modify.on('modifyend', (e) => {
    //removeTextOverlays();
    const featurePosition = e.mapBrowserEvent.coordinate;
    tooltipHolder.helpTooltip.setPosition(featurePosition); // after modify, before next pointermove
    tooltipHolder.helpTooltipElement.classList.remove('hidden');
  });
  return modify;
}

/**
 * pointermove handler for preventing multiple select when modifying
 * @param {event} e openlayers pointermove event
 */
const modifyPointerMoveHandler = function ([map, sketchLayer], e) {
  if (modify.handlingDownUpSequence) {
    return;
  }
  if (e.dragging) {
    return;
  }
  const features = map.getFeaturesAtPixel(e.pixel, {
    layerFilter: function (l) {
      return l == sketchLayer;
    },
  });
  if (features.length && features[0] != modifySource.getFeatures()[0]) {
    modifySource.clear();
    modifySource.addFeature(features[0]);
  }
};

/**
 *
 */
let helpMessagePointerMove, modifyPointerMove;

/**
 * initialize measure tool.
 * @param {module:ol/CanvasMap~CanvasMap} map The map to create the measure interaction for.
 * @return {Function} Click handler function
 */
export function initSketch(map) {
  tempLayer.push(sketchLayer); // remove layers in maplayers.js
  createHelpTooltip(tooltipHolder, map);

  // make sure the addTextInteraction is above the standard popup interaction
  // text interaction
  addTextForSketch = new TextInteraction(map, sketchLayer, tooltipHolder);
  addTextForSketch.setActive(false);
  map.addInteraction(addTextForSketch);
  // drawline interaction
  drawLine.setActive(false);
  map.addInteraction(drawLine);
  // snap interaction
  snap = new Snap({
    source: source,
  });
  snap.setActive(false);
  map.addInteraction(snap);
  // modify interaction
  // set this true when editing text, check it before creating new text
  // contains featureId, if a text is to be modified
  modify.setActive(false);
  map.addInteraction(modify);

  function activateSketchInteraction(type) {
    modifyPointerMove = modifyPointerMoveHandler.bind(this, [map, sketchLayer]);
    map.on('pointermove', modifyPointerMove);
    sketchLayer.setMap(map);

    if (type === 'Point') {
      addTextForSketch.setActive(true);
      modify.setActive(true);
    } else {
      // if type === 'LineString'
      drawLine.setActive(true);
      modify.setActive(true);
      snap.setActive(true);
    }

    // pointerMoveHandler for help messages
    helpMessagePointerMove = function (evt) {
      if (evt.dragging) {
        return;
      }
      let helpMsg = 'Beginnen mit Klick';
      if (tooltipHolder.feature && type !== 'Point') {
        // handle messages for points in textinteraction
        helpMsg = 'Fortsetzen mit Klick, beenden mit 2x Klick';
      }
      if (!tooltipHolder.feature || type !== 'Point') {
        // dont set position while typing
        tooltipHolder.helpTooltipElement.innerHTML = helpMsg;
        tooltipHolder.helpTooltip.setPosition(evt.coordinate);
        tooltipHolder.helpTooltipElement.classList.remove('hidden');
      }
    };
    map.on('pointermove', helpMessagePointerMove);
  }

  function clickHandler(event) {
    stopSketching(map);
    if (event.target.classList.contains('active')) {
      //remove old interactions
      stopMeasure(map);
      // immediately start modifying for imported geojson
      modify.setActive(true);
      // start drawing
      const type = event.target.innerHTML === 'Linie' ? 'LineString' : 'Point';
      activateSketchInteraction(type);
      tooltipHolder.helpTooltip.setMap(map);
    }
  }
  return clickHandler;
}

/**
 * removes drawLine interaction
 * @param {import("ol/Map.js").default} map Map.
 **/
export function stopSketching(map) {
  if (map.hasListener('pointermove', helpMessagePointerMove)) {
    map.un('pointermove', helpMessagePointerMove);
  }
  if (map.hasListener('pointermove', modifyPointerMove)) {
    map.un('pointermove', modifyPointerMove);
  }
  map.removeInteraction();
  map.removeInteraction();

  addTextForSketch.setActive(false);
  drawLine.setActive(false);
  modify.setActive(false);
  snap.setActive(false);
  // remove unfinished Overlays
  removeTextOverlays();
  if (tooltipHolder && tooltipHolder.helpTooltip) {
    tooltipHolder.helpTooltip.setPosition(undefined);
    tooltipHolder.helpTooltip.setMap(null);
  }
}
