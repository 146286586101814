/*
searchbar.js
*/
import Overlay from 'ol/Overlay';
import {warning} from './navigation.js';
//import {popdown} from './popup';
import {fromLonLat, toLonLat} from 'ol/proj';
import {isInAustria} from './maplayers.js';
import {map} from './map.js';
import {removeTextOverlays} from './textinteraction.js';
import {stopMeasure} from './measure.js';
import {stopSketching} from './sketch.js';

const geomarker = document.createElement('div');
geomarker.classList.add('geomarker');
const overlay = new Overlay({
  element: geomarker,
  positioning: 'top-left',
  autoPan: true,
  offset: [-11, -32],
});

function animateTo(center, map, overlay) {
  map.getView().animate(
    {
      center: center,
      zoom: 13,
    },
    function () {
      overlay.setMap(map);
      overlay.setPosition(center);
    }
  );
}

// getFilteredFeatures
// geocoder supplemental function
function getFilteredFeatures(data, genhtml) {
  const country = ['Österreich', 'Oesterreich', 'Austria'];
  const filtered = [];
  const result = [];

  for (let d = 0; d < data.length; d++) {
    const geolego = {
      'name': ', ',
      'street': ' ',
      'housenumber': ', ',
      'postcode': ' ',
      'city': ', ',
      'state': '',
    };

    if (
      typeof data[d].properties.country != 'undefined' &&
      country.indexOf(data[d].properties.country) > -1
    ) {
      let tmp = '';
      for (const l in geolego) {
        if (
          typeof data[d].properties[l] != 'undefined' &&
          data[d].properties[l].toString().length > 0
        ) {
          tmp += data[d].properties[l] + geolego[l];
        }
      }
      if (tmp != '' && filtered.indexOf(tmp) == -1) {
        filtered.push(tmp);
        if (genhtml) {
          // Return HTML for LiveSearch
          result.push(
            '<div class="livesearchrow oneresult" target="' +
              data[d].geometry.coordinates.join(',') +
              '">' +
              tmp +
              '</div>'
          );
        } else {
          result.push([tmp, data[d].geometry.coordinates]);
        }
      }
    }
  }
  return result;
}

let lastpress = 0;
let searchjob = null;

// mapzoomto - zoomt auf Element, das angeklickt, oder gewählt wurde!
function mapzoomto(eob) {
  const sstring = eob.innerHTML;
  const cords = eob.getAttribute('target').split(',');
  const lonlat = fromLonLat([parseFloat(cords[0]), parseFloat(cords[1])]);
  hideunwantedstuff(true, true);
  document.querySelector('#searchinput').value = sstring;

  document.querySelector('#searchbutton').classList.add('hidden');
  document.querySelector('#searchreset').classList.remove('hidden');
  animateTo(lonlat, map, overlay);
}

// get_geolocation - gets geolocation and zooms to...
export function get_geolocation() {
  spinnercontrol(1);
  navigator.geolocation.getCurrentPosition(
    function (pos) {
      hideunwantedstuff(true, true);
      const lonlat = fromLonLat([pos.coords.longitude, pos.coords.latitude]);

      document.querySelector('#searchbutton').classList.add('hidden');
      document.querySelector('#searchreset').classList.remove('hidden');
      animateTo(lonlat, map, overlay);

      spinnercontrol(0);
    },
    function () {
      spinnercontrol(0);
      warning('Positionsbestimmung derzeit nicht möglich!');
    },
    {timeout: 5000}
  );
}

// search_event - live search event handler
export function search_event(event, what) {
  const eob = event.srcElement;
  if (eob.classList.contains('livesearchrow')) {
    if (what == 'mouse') {
      if (document.querySelector('.livesearchrow.selected')) {
        document
          .querySelector('.livesearchrow.selected')
          .classList.remove('selected');
      }
      eob.classList.add('selected');
    }
    if (what == 'click') {
      mapzoomto(eob);
    }
  }
}

function selectionmove(dir) {
  const cresults = document.querySelectorAll('.livesearchrow');
  if (cresults.length > 0) {
    let cpos = -1;
    for (let r = 0; r < cresults.length; r++) {
      if (cresults[r].classList.contains('selected')) {
        cpos = r;
      }
    }
    cpos += dir;
    if (cpos < 0) {
      cpos = cresults.length - 1;
    }
    if (cpos >= cresults.length) {
      cpos = 0;
    }
    if (document.querySelector('.livesearchrow.selected')) {
      document
        .querySelector('.livesearchrow.selected')
        .classList.remove('selected');
    }
    cresults[cpos].classList.add('selected');
  }
}

// while_typing - live search input watcher...
export function while_typing(event) {
  document.querySelector('#searchinput').classList.remove('returnpressed');
  if (document.querySelector('#searchbutton').classList.contains('hidden')) {
    search_reset(true);
  }
  switch (event.keyCode) {
    case 40:
      selectionmove(1);
      break;
    case 38:
      selectionmove(-1);
      break;
    case 13:
      if (document.querySelector('.livesearchrow.selected')) {
        mapzoomto(document.querySelector('.livesearchrow.selected'));
      } else {
        search_now();
      }
      break;
    default:
      if (Date.now() - lastpress < 300) {
        clearTimeout(searchjob);
      }
      if (document.querySelector('.livesearchrow.selected')) {
        document
          .querySelector('.livesearchrow.selected')
          .classList.remove('selected');
      }
      lastpress = Date.now();
      hideunwantedstuff(true, false);
      searchjob = setTimeout(live_search, 300);
      break;
  }
}

function live_search() {
  const sstring = document.getElementById('searchinput').value;
  if (
    sstring.length >= 3 &&
    !sstring.match(/^[\d,\.\s]+$/) &&
    !document.querySelector('#searchinput').classList.contains('returnpressed')
  ) {
    const mapcenter = toLonLat(map.getView().getCenter());
    const geoUri =
      'https://photon.komoot.io/api/?q=' +
      encodeURIComponent(sstring) +
      '&lat=' +
      mapcenter[1] +
      '&lon=' +
      mapcenter[0] +
      '&limit=4&lang=de';
    fetch(geoUri)
      .then((resp) => resp.json())
      .then(function (data) {
        const results = getFilteredFeatures(data.features, true);
        if (results.length == 0) {
          document.querySelector('#livesearch').innerHTML = '';
          document.querySelector('#livesearch').classList.add('hidden');
        } else {
          document.querySelector('#livesearch').innerHTML = results.join('');
          document.querySelector('#livesearch').classList.remove('hidden');
        }
        //console.log(results); //eslint-disable-line
      });
  } else {
    document.querySelector('#livesearch').innerHTML = '';
    document.querySelector('#livesearch').classList.add('hidden');
  }
}

// spinnercontrol - Spinner ein- / ausschalten
export function spinnercontrol(onoff) {
  if (onoff == 1) {
    document.querySelector('.grayout').classList.remove('hideme');
    document.querySelector('.spinner').classList.remove('hideme');
  } else {
    document.querySelector('.grayout').classList.add('hideme');
    document.querySelector('.spinner').classList.add('hideme');
  }
}

// search_reset() - resets search bar
export function search_reset(noclear) {
  if (!noclear) {
    document.querySelector('#searchinput').value = '';
  }
  document.querySelector('#searchinput').classList.remove('returnpressed');
  document.querySelector('#searchbutton').classList.remove('hidden');
  document.querySelector('#searchreset').classList.add('hidden');
  overlay.setPosition(undefined);
  overlay.setMap(null);
}

// search_now() - starts the search when enter is pressed - or takes live search result
export function search_now() {
  document.querySelector('#livesearch').classList.add('hidden');
  document.querySelector('#searchinput').classList.add('returnpressed');
  const sstring = document.getElementById('searchinput').value;
  let lonlat = [0, 0];
  let errmsg =
    'Kein gültiger Suchbegriff! Bitte geben Sie entweder eine Adresse ein oder Geokoordinaten in Form von Dezimalkoordinaten.';
  // Koordinaten oder String?
  const cordm = sstring.match(/\s*(\d+\.*\d*)[^\d]*[,\s]+(\d+\.*\d*)[^\d]*\s*/);
  if (cordm) {
    lonlat = fromLonLat([parseFloat(cordm[2]), parseFloat(cordm[1])]);
    if (!isInAustria(lonlat)) {
      errmsg =
        'Die eingegbenen Geokoordinaten liegen nicht innerhalb Österreichs!';
      warning(errmsg);
    } else {
      document.querySelector('#searchbutton').classList.add('hidden');
      document.querySelector('#searchreset').classList.remove('hidden');
      animateTo(lonlat, map, overlay);
    }
  } else {
    spinnercontrol(1);
    const mapcenter = toLonLat(map.getView().getCenter());
    const geoUri =
      'https://photon.komoot.io/api/?q=' +
      encodeURIComponent(sstring) +
      '&lat=' +
      mapcenter[1] +
      '&lon=' +
      mapcenter[0] +
      '&limit=5&lang=de';
    fetch(geoUri)
      .then((resp) => resp.json())
      .then(function (data) {
        spinnercontrol(0);
        const results = getFilteredFeatures(data.features);
        if (results.length > 0) {
          hideunwantedstuff(true, true);
          document.querySelector('#searchinput').value = results[0][0];
          lonlat = fromLonLat(results[0][1]);

          document.querySelector('#searchbutton').classList.add('hidden');
          document.querySelector('#searchreset').classList.remove('hidden');
          animateTo(lonlat, map, overlay);
        } else {
          warning(errmsg);
        }
      });
  }
}

// hideunwantedstuff - schließt alles, was nicht offen gebraucht wird bei bestimmten Aktionen
export function hideunwantedstuff(infomenu, livesearch) {
  stopMeasure();
  stopSketching(map);
  removeTextOverlays();
  if (document.querySelector('#toolmenu').querySelector('.toolbutton.active')) {
    document
      .querySelector('#toolmenu')
      .querySelector('.toolbutton.active')
      .classList.remove('active');
  }
  //popdown();
  if (
    infomenu &&
    document.querySelector('#infomenu').classList.contains('slideupinfomenu')
  ) {
    document.getElementById('infomenu').classList.add('slidedowninfomenu');
    document.getElementById('infomenu').classList.remove('slideupinfomenu');
  }
  if (livesearch) {
    document.querySelector('#livesearch').innerHTML = '';
    document.querySelector('#livesearch').classList.add('hidden');
  }
}
