import markup from './toolmenu.thtml';
import {exportGeoJSON, removeLayer} from './maplayers.js';
import {initJsonImport} from './json-import.js';
import {initPDF} from './pdf.js';
import {initSketch} from './sketch.js';
import {measure} from './measure.js';
import {removeTextOverlays} from './textinteraction.js';

function initToggle(buttons, map) {
  const targetNumber = buttons.length;
  function toggleHandler(event) {
    const target = event.target;
    //get all the other targets
    const otherTargets = [];
    for (let i = 0; i < buttons.length; i++) {
      if (buttons[i] !== target) {
        otherTargets.push(buttons[i]);
      }
    }
    if (target.classList.contains('active')) {
      target.classList.remove('active');
    } else {
      for (let i = 0; i < buttons.length - 1; i++) {
        otherTargets[i].classList.remove('active');
      }
      target.classList.add('active');
    }
  }
  for (let i = 0; i < targetNumber; i++) {
    buttons[i].addEventListener('click', toggleHandler);
  }
}

export function initToolMenu(container, map) {
  container.innerHTML = markup();

  // Connect pdf tool to menu
  const clickPdf = initPDF(map, 'a4');
  const pdfButton = document.getElementById('pdfButton');
  pdfButton.addEventListener('click', clickPdf);

  // init json import button
  const importJsonButton = document.getElementById('jsonImport');
  importJsonButton.addEventListener('click', initJsonImport(map));

  // init delete json button
  const deleteButtons = document.querySelectorAll('.icon-trash');
  for (let i = 0; i < deleteButtons.length; i++) {
    deleteButtons[i].addEventListener('click', removeThisLayer());
  }

  const exportjsonButton = document.getElementById('exportjsonButton');
  exportjsonButton.addEventListener('click', exportGeoJSON);

  const drawLineButton = document.getElementById('drawLine');
  const drawTextButton = document.getElementById('drawText');

  // Connect measure tool to menu
  const lineButton = document.querySelector(
    '.toolbutton[data-type="LineString"]'
  );
  const polyButton = document.querySelector('.toolbutton[data-type="Polygon"]');
  initToggle([drawLineButton, drawTextButton, lineButton, polyButton], map);
  const clickMeasure = measure(map);
  // add these event listeners after the toggling
  drawLineButton.addEventListener('click', initSketch(map));
  drawTextButton.addEventListener('click', initSketch(map));
  lineButton.addEventListener('click', clickMeasure);
  polyButton.addEventListener('click', clickMeasure);
}

function removeThisLayer() {
  return function (evt) {
    // get layerName which is stored in data-layer of element
    const layerName = evt.target.dataset.layer;
    removeLayer(layerName);
    if (layerName === 'sketch' || layerName === 'importedGeoJSON') {
      removeTextOverlays();
    }
  };
}
