import './colors.css';
import './fontello.css';
import './index.css';
import './olbeautify.css';
import 'ol/ol.css';
import olmarkup from './overlayelements.thtml';
import sync from 'ol-hashed';
import {info_menu, initInfoMenu, initNavigation} from './navigation.js';
import {initLegend} from './legend.js';
import {initMap, map} from './map.js';
import {initMapControls} from './mapcontrols.js';
import {initPopUp} from './popup.js';
import {initToolMenu} from './toolmenu.js';
import {layerHashSync} from './layerhash.js';
//import {debug as layerswitcher} from './debug.layerswitcher.js';
import {generalClickHandler} from './clickhandler.js';
import {loadBounds} from './maplayers.js';
import {registerLayers} from './style.js';
import {showStartPopUp} from './startpopup.js';
import {syncLayers} from './layers.js';

initMap('map');

loadBounds();

// Set up ol-hashed to update the url hash according to map center/zoom
sync(map);

const mapol = document.querySelector('#map');
mapol.insertAdjacentHTML('beforeend', olmarkup({}));

layerHashSync(map);

// Register layers for `./style.js`
registerLayers(map);
// Set up synchronization for controlling layers through `./layers.js~layers`,
syncLayers(map);

/* PopUp  Stuff Initialize */
initPopUp(map);

/* Navigation Stuff Initialize */
initNavigation(document.querySelector('#navigationbar'));
initInfoMenu(document.querySelector('#infomenu'));

/* Navigation Stuff Initialize */
document
  .querySelector('#infomenubutton')
  .addEventListener('click', function () {
    info_menu('switch');
  });

initToolMenu(document.querySelector('#toolmenu'), map);

/*
const layerSwitcherContainer = document.createElement('div');
map.getTargetElement().appendChild(layerSwitcherContainer);
layerswitcher(layerSwitcherContainer, map);
*/

/* Init map controls behavior */
initLegend(document.querySelector('#legendcontainer'), map);
initMapControls(mapol);

/* show initially hidden elements after 500ms */
window.setTimeout(function () {
  const llist = document.querySelectorAll('.hiddenfirst');
  for (let e = 0; e < llist.length; e++) {
    llist[e].classList.remove('hiddenfirst');
  }
}, 500);

showStartPopUp(); // DIESE ZEILE NACH BELIEBEN WIEDER ENTFERNEN!

document.querySelector('body').addEventListener('click', generalClickHandler);
