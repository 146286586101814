<div id="layercontainerbutton" for="legendcontainer" class="myicon-info map-control control-l switchbutton ol-unselectable">
    <div>Legende</div>
</div>
<div id="layermenubutton" for="layermenu" class="map-control control-1 switchbutton ol-unselectable">
    <div>Karten-<br>Steuerung</div>
</div>
<div id="toolmenubutton" for="toolmenu" target="310" class="map-control control-2 switchbutton ol-unselectable">
    <div>Tool-<br>box</div>
</div>
<div id="zoomwarning" class="zoomwarning hidden"><i class="usericon icon-info"></i><span>Die Lagegenauigkeit ist in diesem Maßstab nicht gewährleistet!<br>(Erhebungsgenauigkeit 1:10.000)</span></div>
