// START POP UP
// Zeigt Start-PopUp am Anfang

import './startpopup.css';

export function showStartPopUp() {
  if (!getCookie('ebod2019')) {
    const popupcontent =
      '<div><h1>Willkommen zur eBOD</h1><p><br/>Die eBOD stellt grundlegende Informationen zu den landwirtschaftlich genutzten Böden Österreichs kostenlos zur Verfügung. Intuitive Bedienbarkeit, erweiterte Funktionalität (GPS-Unterstützung, Druckfunktion, Geodaten-Import), sowie Einsatzmöglichkeit auf allen Geräten mit aktuellen Browsern zeichnen die Anwendung aus. Sollten dennoch Startschwierigkeiten auftreten, steht unser Team unter <a href="mailto:ebod2@bfw.gv.at">ebod2@bfw.gv.at</a> gerne für Sie zur Verfügung!</p><span id="spopupok">OK</span></div>';

    document.querySelector('.grayout').classList.remove('hideme');
    const startpop = document.createElement('div');
    startpop.classList.add('startpopup');
    document.querySelector('body').appendChild(startpop);

    document.querySelector('.startpopup').innerHTML = popupcontent;
    document.querySelector('#spopupok').addEventListener('click', function () {
      const spop = document.querySelector('.startpopup');
      document.querySelector('body').removeChild(spop);
      document.querySelector('.grayout').classList.add('hideme');
      setCookie('ebod2019', 'ok', 180);
    });
  }
}

function setCookie(cname, cvalue, exdays) {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  const expires = 'expires=' + d.toUTCString();
  document.cookie = cname + '=' + cvalue + '; ' + expires;
}

function getCookie(cname) {
  const name = cname + '=';
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) != -1) {
      return c.substring(name.length, c.length);
    }
  }
  return null;
}
