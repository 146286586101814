<h2>GeoJSON import:</h2>
<p>
Hier wird die Möglichkeit geboten Daten (Polygone, Linien, Punkte, Texte [Grundstücke, Trassen, etc.]) im GeoJSON-Format in die Karte (im Browser) zu importieren.
</p>
Zu importierende Files (shp, gpx, dxf, etc.) können z.B. mit 2geojson.com konvertiert werden. Zu importierende Daten müssen in WGS84 EPSG:4326 bereitgestellt werden.
<p>
Texte müssen in Punkten als Property 'text' angegeben werden.
</p>
<h2>Zeichnen und Messen:</h2>
<p>
  Innerhalb der eBOD erstellte Zeichnungen und Texte können als GeoJSON exportiert und später wieder importiert werden.<br />
  Sämtliche Zeichnungen und Bemaßungen können auch als Bild via pdf exportiert werden.
</p>
