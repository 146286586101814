/**
 * This module contains constants for the basemap
 * and tool box
 */

import GeoJSON from 'ol/format/GeoJSON';
import MVT from 'ol/format/MVT';
import TileGrid from 'ol/tilegrid/TileGrid';
import TileLayer from 'ol/layer/Tile';
import TopoJSON from 'ol/format/TopoJSON';
import Vector from 'ol/source/Vector';
import VectorImageLayer from 'ol/layer/VectorImage';
import VectorLayer from 'ol/layer/Vector';
import VectorTile from './vectortilesource.js';
import VectorTileLayer from 'ol/layer/VectorTile';
import WebGLTileLayer from 'ol/layer/WebGLTile.js';
import XYZ from 'ol/source/XYZ';
import style from './style.js';
import {GeoTIFF} from 'ol/source.js';
import {asArray} from 'ol/color.js';
import {clamp} from 'ol/math';
import {containsCoordinate, getTopLeft} from 'ol/extent';
import {createXYZ} from 'ol/tilegrid';
import {get as getProj} from 'ol/proj';
import {legendenHash} from './legend.js';

export const austriaExtent = [1060958, 5840214, 1910329, 6278345];
export const bmapGrauMinRes = 4.7774;
export const bmapGrauMaxRes = 10000;
export const bmapOrthoMinRes = 0.1;
export const bmapOrthoMaxRes = 4.7774;
const bmapExtent = [977650, 5838030, 1913530, 6281290];

const geoJSON = new GeoJSON();

let austriaGeom;
export function isInAustria(coordinate) {
  return austriaGeom
    ? austriaGeom.intersectsCoordinate(coordinate)
    : containsCoordinate(austriaExtent, coordinate);
}

export function getBorderPoint(coordinate) {
  return austriaGeom
    ? austriaGeom.getClosestPoint(coordinate)
    : [
        clamp(coordinate[0], austriaExtent[0], austriaExtent[2]),
        clamp(coordinate[1], austriaExtent[1], austriaExtent[3]),
      ];
}

const bodenkarteSource = new VectorTile({
  transition: 0,
  tileGrid: new TileGrid({
    extent: austriaExtent,
    minZoom: 6,
    origin: getTopLeft(getProj('EPSG:3857').getExtent()),
    resolutions: createXYZ({
      maxZoom: 15,
      tileSize: 512,
    }).getResolutions(),
    tileSize: 512,
  }),
  format: new MVT(),
  tileUrlFunction: function (tileCoord, pixelRatio, projection) {
    return austriaGeom.intersectsExtent(
      bodenkarteSource.getTileGrid().getTileCoordExtent(tileCoord)
    )
      ? `data/bodenkarte-tiles/${tileCoord[0]}/${tileCoord[1]}/${tileCoord[2]}.pbf`
      : undefined;
  },
});
export const bodenkarte = new VectorTileLayer({
  idField: 'bofo_id',
  name: ['bodenform_mpoly', 'sonstigeflaechen_mpoly'],
  opacity: 0.6,
  style: style,
});

export const profil = new VectorLayer({
  idField: 'prof7_id',
  name: 'profil_point',
  source: new Vector({
    url: 'data/profil_point.geojson',
    format: geoJSON,
  }),
});
// Bind style function to the layer, for access to the layer's `name`.
profil.setStyle(style.bind(profil));
bodenkarte.set('bound', {bodenform_mpoly: profil});
bodenkarte.on('change:visible', (e) => {
  profil.setVisible(bodenkarte.getVisible());
});

const rasterSource = import.meta.env.VITE_CI
  ? undefined
  : new GeoTIFF({
      sources: [
        {
          url: './data/Erosionskarte_2018-3857-deflate-cog.tif',
          nodata: -9999,
        },
      ],
      normalize: false,
    });
const erosionSymbols = legendenHash.erosionskarte
  .map((entry) => [entry.ord, asArray(entry.color)])
  .flat();
export const rasterkarte = new WebGLTileLayer({
  name: ['erosionskarte'],
  source: rasterSource,
  visible: false,
  opacity: 0.6,
  style: {
    color: ['match', ['band', 1], ...erosionSymbols, [0, 0, 0, 0]],
  },
});

export const kartierungsbereich = new VectorImageLayer({
  opacity: 0.6,
  idField: 'kb_id',
  name: 'kartierungsbereich_mpoly',
  source: new Vector({
    url: 'data/kartierungsbereich_mpoly.topojson',
    format: new TopoJSON(),
  }),
});
// Bind style function to the layer, for access to the layer's `name`.
kartierungsbereich.setStyle(style.bind(kartierungsbereich));

const bmapTilegrid = new TileGrid({
  extent: bmapExtent,
  origin: [-20037508.3428, 20037508.3428],
  resolutions: createXYZ({
    maxZoom: 18,
  }).getResolutions(),
});

const bmapAttribution =
  '<a target="_blank" href="http://www.basemap.at">basemap.at</a> &copy; <a target="_blank" href="http://creativecommons.org/licenses/by/3.0/at/">CC BY 3.0 AT</a>';

const bmapGrauSource = new XYZ({
  attributions: bmapAttribution,
  crossOrigin: 'anonymous',
  tileGrid: bmapTilegrid,
  url: 'https://mapsneu.wien.gv.at/basemap/bmapgrau/normal/google3857/{z}/{y}/{x}.png',
});
export const bmapGrau = new TileLayer({
  name: 'bmapgrau',
  source: bmapGrauSource,
  minResolution: bmapGrauMinRes,
  maxResolution: bmapGrauMaxRes,
});

const bmapOrthoSource = new XYZ({
  attributions: bmapAttribution,
  crossOrigin: 'anonymous',
  tileGrid: bmapTilegrid,
  url: 'https://mapsneu.wien.gv.at/basemap/bmaporthofoto30cm/normal/google3857/{z}/{y}/{x}.jpg',
});
export const bmapOrtho = new TileLayer({
  name: 'bmaporthofoto30cm',
  source: bmapOrthoSource,
  minResolution: bmapOrthoMinRes,
  maxResolution: bmapOrthoMaxRes,
});

// Load `austriaGeom` so we can control which tiles to load for `bodenkarte`.
export function loadBounds() {
  fetch('data/oe-grenze-simplified-500.geojson').then((result) => {
    result.json().then((json) => {
      const features = geoJSON.readFeatures(json, {
        featureProjection: 'EPSG:3857',
      });
      austriaGeom = features[0].getGeometry();
      bodenkarte.setSource(bodenkarteSource);
    });
  });
}

export const tempLayer = [];

export function removeLayer(layerName) {
  for (let i = 0; i < tempLayer.length; i++) {
    const thisLayer = tempLayer[i];
    if (thisLayer.get('name') === layerName) {
      thisLayer.setMap(null);
      thisLayer.getSource().clear();
    }
  }
}

export function getTempLayer(layerName) {
  for (let i = 0; i < tempLayer.length; i++) {
    const thisLayer = tempLayer[i];
    if (thisLayer.get('name') === layerName) {
      return thisLayer;
    }
  }
}

export function exportGeoJSON() {
  const exportLayer = getTempLayer('sketch');
  const writer = new GeoJSON({
    dataProjection: 'EPSG:4326',
    featureProjection: 'EPSG:3857',
  });
  const geojsonStr = writer.writeFeatures(
    exportLayer.getSource().getFeatures()
  );
  const filename = 'ebod_map_export.geojson';

  const element = document.createElement('a');
  if (navigator.msSaveBlob) {
    const blob = new Blob([geojsonStr]);
    element.addEventListener('click', function () {
      navigator.msSaveBlob(blob, filename);
    });
    element.click();
  } else {
    document.body.appendChild(element);
    element.setAttribute(
      'href',
      'data:application/json;charset=utf-8,' + encodeURIComponent(geojsonStr)
    );
    element.setAttribute('download', filename);
    element.target = '_self';
    element.click();
    document.body.removeChild(element);
  }
}
