import OlVectorTileSource from 'ol/source/VectorTile';
import {containsExtent, intersects} from 'ol/extent';
import {listen, listenOnce} from 'ol/events';

/**
 * VectorTile source with an additional `getFeaturesInExtent` method.
 * @param {module:ol/source/VectorTile~Options} options Options.
 * @class
 */
class VectorTileSource extends OlVectorTileSource {
  constructor(options) {
    super(options);
    this.availableTiles = {};
    listen(this, 'tileloadend', (e) => {
      this.availableTiles[e.tile.tileCoord] = e.tile;
      listenOnce(e.tile, 'change', (e) => {
        delete this.availableTiles[e.target.tileCoord];
      });
    });
  }
}

/**
 * Get the features for the tiles that intersect the given extent. Note that
 * this may contain duplicate features (if they span across multiple tiles).
 * @param {module:ol/extent~Extent} extent Extent.
 * @return {Array<module:ol/render/Feature~Feature|ol/Feature~Feature>} Features.
 */
VectorTileSource.prototype.getFeaturesInExtent = function (extent) {
  const tileGrid = this.getTileGrid();
  let features = [];
  for (const key in this.availableTiles) {
    const tile = this.availableTiles[key];
    const tileExtent = tileGrid.getTileCoordExtent(tile.tileCoord);
    if (intersects(extent, tileExtent)) {
      const tileFeatures = tile.getFeatures();
      if (containsExtent(extent, tileExtent)) {
        features = features.concat(tileFeatures);
      } else {
        for (let i = 0, ii = tileFeatures.length; i < ii; ++i) {
          const feature = tileFeatures[i];
          if (intersects(extent, feature.getGeometry().getExtent())) {
            features.push(feature);
          }
        }
      }
    }
  }
  return features;
};

export default VectorTileSource;
