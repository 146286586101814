import Map from 'ol/Map';
import View from 'ol/View';
import {
  MousePositionControl,
  ScaleLineControl,
  zoomWarning,
} from './mapcontrols.js';
import {
  austriaExtent,
  bmapGrau,
  bmapOrtho,
  bodenkarte,
  getBorderPoint,
  isInAustria,
  kartierungsbereich,
  profil,
  rasterkarte,
} from './maplayers.js';
import {defaults as controlDefaults} from 'ol/control';
import {defaults as interactionDefaults} from 'ol/interaction';

export let map;

export function initMap(target) {
  const view = new View({
    constrainOnlyCenter: true,
    extent: austriaExtent,
    minZoom: 5,
    maxZoom: 20,
  });

  map = new Map({
    target: target,
    controls: controlDefaults({
      attributionOptions: {
        collapsed: false,
        collapsible: false,
      },
    }).extend([MousePositionControl, ScaleLineControl]),
    interactions: interactionDefaults({
      altShiftDragRotate: false,
      pinchRotate: false,
    }),
    layers: [
      bmapOrtho,
      bmapGrau,
      bodenkarte,
      kartierungsbereich,
      rasterkarte,
      profil,
    ],
    view: view,
  });
  map.on('moveend', function () {
    zoomWarning(view.getZoom() > 16);
    const center = view.getCenter();
    if (!isInAustria(center)) {
      setTimeout(function () {
        view.animate({center: getBorderPoint(center), duration: 350});
      }, 0);
    }
  });

  profil.setMaxResolution(map.getView().getResolutionForZoom(13) + 1e-8);
  map.getView().fit(austriaExtent, {constrainResolution: false});
  return map;
}
