<div class="layer-head smaller">
  <div class="draghead"></div>
  <div class="inside-layer-head">
    <i class="usericon icon-info-circled-alt"></i>
    <span id="popuphead"></span>
  </div>
  <i class="usericon icon-close-1 hover closethis"></i>
</div>
<div class="popupcontent"></div>
<div class="printme"><i class="usericon icon-print"></i> Druck-Ansicht</div>
<div class="moveableinfo">Verschieben durch Ziehen der Titelleiste!</div>
