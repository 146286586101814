<div class="nav-title"><img class="bfwhoch" src="data/bfw-logo-hoch.png"><a href="/" class="ebodnavlogo"></a><span class="ebodgreen bfa baw">Digitale Bodenkarte</span><span class="ebodgreen bfw bfa respbig">Bundesamt für Wasserwirtschaft</span><span class="ebodgreen bfw bfa respsmall">BA für Wasserwirtschaft</span><span class="ebodgreen bfw baw">Bio Forschung Austria</span></div>
<div class="nav-search">
  <div class="nav-input">
    <i id="geolocation" class="usericon icon-target-1"></i>
     <input id="searchinput" name="adresse" id="adresse" placeholder="Adresse oder Koordinaten">
     <i id="searchbutton" class="usericon icon-search-2"></i>
     <i id="searchreset" class="usericon icon-close-1 hover hidden"></i>
     <div id="livesearch" class="livesearch hidden">
     </div>
   </div>
</div>
<div class="nav-logo">
  <a href="https://bfw.ac.at" target="_blank" class="bfa baw"><img src="data/logo-bfw.png"></a>
  <a href="https://www.bioforschung.at" target="_blank" class="bfw baw"><img src="data/logo-bfa.png"></a>
  <a href="https://www.baw.at" target="_blank" class="bfw bfa"><img src="data/logo-baw.png"></a>
  <i id="infomenubutton" class="usericon icon-menu-2"></i>
</div>
