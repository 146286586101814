/**
 * This module contains all controll routines for handling of the Menu Button
 *
 */

import './infomenu.css';
import './navigation.css';
import './spinner.css';
import markupc from './contentpage.thtml';
import markupi from './infomenu.thtml';
import markupn from './navigation.thtml';
import markups from './spinner.thtml';
import markupw from './warningbox.thtml';
import {
  get_geolocation,
  hideunwantedstuff,
  search_event,
  search_now,
  search_reset,
  while_typing,
} from './searchbar.js';

export function initNavigation(container) {
  container.innerHTML = markupn({});
  // Events Search Bar
  document
    .getElementById('searchinput')
    .addEventListener('keydown', function (event) {
      while_typing(event);
    });
  document
    .getElementById('searchbutton')
    .addEventListener('click', function () {
      search_now();
    });
  document.getElementById('searchreset').addEventListener('click', function () {
    search_reset();
  });
  document
    .getElementById('livesearch')
    .addEventListener('click', function (event) {
      search_event(event, 'click');
    });
  document
    .getElementById('livesearch')
    .addEventListener('mouseover', function (event) {
      search_event(event, 'mouse');
    });

  document.getElementById('geolocation').addEventListener('click', function () {
    get_geolocation();
  });

  // Gray Out
  const grayout = document.createElement('div');
  grayout.classList.add('grayout');
  grayout.classList.add('hideme');
  container.parentNode.appendChild(grayout);
  // Spinner
  const spinner = document.createElement('div');
  spinner.innerHTML = markups({});
  spinner.classList.add('spinner');
  spinner.classList.add('hideme');
  container.parentNode.appendChild(spinner);
  // Warning Box
  const wbox = document.createElement('div');
  wbox.classList.add('warningbox');
  wbox.classList.add('hideme');
  wbox.innerHTML = markupw({});
  container.parentNode.appendChild(wbox);
  document
    .querySelector('.warningbutton')
    .addEventListener('click', function () {
      document.querySelector('.grayout').classList.add('hideme');
      document.querySelector('.warningbox').classList.add('hideme');
    });
  // content page container
  const cpcontainer = document.createElement('div');
  cpcontainer.innerHTML = markupc({});
  cpcontainer.setAttribute('id', 'contentpage');
  cpcontainer.setAttribute('current', '');
  cpcontainer.classList.add('contentpage');
  cpcontainer.classList.add('slidedowncontentpage');
  cpcontainer.classList.add('hiddenfirst');
  container.parentNode.appendChild(cpcontainer);
}

export function warning(msg) {
  document.querySelector('.warningcontent').innerHTML = msg;
  document.querySelector('.grayout').classList.remove('hideme');
  document.querySelector('.warningbox').classList.remove('hideme');
}

export function initInfoMenu(container) {
  container.innerHTML = markupi({});
  const allinav = container.querySelectorAll('.info-menu-row');
  for (let i = 0; i < allinav.length; i++) {
    allinav[i].addEventListener('click', function () {
      info_page(this);
    });
  }
}

// info_menu - info menu and info page operations
export function info_menu(mode) {
  hideunwantedstuff(false, true);
  if (mode == 'switch') {
    if (
      document
        .getElementById('infomenu')
        .classList.contains('slidedowninfomenu')
    ) {
      hideonopen();
      document.getElementById('infomenu').classList.add('slideupinfomenu');
      document.getElementById('infomenu').classList.remove('slidedowninfomenu');
    } else {
      document.getElementById('infomenu').classList.add('slidedowninfomenu');
      document.getElementById('infomenu').classList.remove('slideupinfomenu');
    }
  }
}

// info_page - shows one content page from info menu
export function info_page(ipage, hashid) {
  hideonopen();
  const cpage = document.querySelector('.contentpage');
  let pname = '';
  let ptitle = '';
  if (typeof ipage == 'string') {
    const allMenu = document.getElementsByClassName('info-menu-row');
    for (const link of allMenu) {
      if (link.getAttribute('for') == ipage) {
        pname = link.getAttribute('for');
        ptitle = link.innerHTML;
      }
    }
    if (pname == '') {
      return;
    }
  } else {
    pname = ipage.getAttribute('for');
    ptitle = ipage.innerHTML;
  }
  if (cpage.getAttribute('current') == pname) {
    if (hashid && document.getElementById(hashid)) {
      document.getElementById(hashid).scrollIntoView();
    }
  } else {
    // first show spinner...
    document.querySelector('.grayout').classList.remove('hideme');
    document.querySelector('.spinner').classList.remove('hideme');
    cpage.setAttribute('current', pname);
    fetch('./data/content/' + pname + '.html')
      .then((resp) => resp.text())
      .then(function (data) {
        cpage.querySelector('.titlehtml').innerHTML = ptitle;
        cpage.querySelector('.contentpagehtml').innerHTML = data;
        cpage.querySelector('.contentpagehtml').scrollTop = 0;
        document.querySelector('.spinner').classList.add('hideme');
        document
          .querySelector('.contentpage')
          .classList.remove('slidedowncontentpage');
        document
          .querySelector('.contentpage')
          .classList.add('slideupcontentpage');

        if (hashid && document.getElementById(hashid)) {
          document.getElementById(hashid).scrollIntoView();
        }
      })
      .catch(function () {
        alert(
          'Ein Fehler ist bei der Server-Anfrage aufgetreten. Bitte versuchen Sie es nocheinmal oder informieren Sie den Website-Inhaber. Danke!'
        );
      });
  }
}

// hideonopen - schließt alle offenen Menus
export function hideonopen() {
  hideunwantedstuff(false, true);
  const elist = document.getElementsByClassName('hideonopen');
  for (let e = 0; e < elist.length; e++) {
    const id = elist[e].getAttribute('id');
    elist[e].classList.add('slidedown' + id);
    elist[e].classList.remove('slideup' + id);
  }
}
