import config from '../data/config.json';
import hashed from 'hashed';
import {
  bmapGrauMaxRes,
  bmapGrauMinRes,
  bmapOrthoMaxRes,
  bmapOrthoMinRes,
  bodenkarte,
  kartierungsbereich,
  rasterkarte,
} from './maplayers.js';
import {initLayerMenu} from './layermenu.js';
import {layers} from './layers.js';

const codes = {
  bmapgrau: 'g',
  bmaporthofoto30cm: 'o',
  code: 'c',
  kb_id: 'kb',
};
for (const key in config.topics) {
  codes[key] = config.topics[key].hash;
}

function getKeyByValue(object, value) {
  for (const key in object) {
    if (object[key] === value) {
      return key;
    }
  }
}

function applyDataDomain(domain) {
  if (!domain) {
    domain = 'bfw';
  }
  const domains = Object.keys(config.groups);
  if (domains.indexOf(domain) == -1) {
    throw new Error(
      'Unknown data domain. Allowed values are ' + domains.join()
    );
  }

  if (domain !== document.body.className) {
    document.body.className = domain;

    // set class name on body for styling etc.
    document.body.className = domain;

    // set default layer for the domain
    for (const key in config.topics) {
      const topic = config.topics[key];
      if (
        topic.default === domain ||
        (topic.domain === domain && topic.default === true)
      ) {
        const raster = topic.type === 'raster';
        bodenkarte.setVisible(!raster);
        rasterkarte.setVisible(raster);
        layers.bodenform_mpoly.type = raster ? undefined : key;
      }
    }

    initLayerMenu();

    if (
      document.getElementById('themenswitchtext') &&
      config.themedMapsTitle[domain]
    ) {
      document.getElementById('themenswitchtext').textContent =
        config.themedMapsTitle[domain];
    }
  }
}

let update;

/**
 * Sets the data domain (e.g. bfw, baw, bfa).
 * @param {string} domain The new data domain
 */
export function setDataDomain(domain) {
  update({
    d: domain,
  });
  applyDataDomain(domain);
}

/**
 * Set up layer hash sync.
 * @param {*} map Map.
 * @return {Function|undefined} unregister function
 */
export function layerHashSync(map) {
  const view = map.getView();
  if (!view.isDef()) {
    return;
  }
  const defaultLayer = layers.bodenform_mpoly.type;
  const defaultLayerCode = codes[defaultLayer] || 'b';
  const config = {
    d: {
      // data domain
      default: 'bfw',
      serialize: function (string) {
        return string;
      },
      deserialize: function (string) {
        return string;
      },
    },
    bm: {
      // basemap
      default: 'a',
      serialize: function (string) {
        return string;
      },
      deserialize: function (string) {
        return string;
      },
    },
    l: {
      // layers
      default: [defaultLayerCode, 'false', '60', 'kb'],
      serialize: function (layersArray) {
        return layersArray.join(',');
      },
      deserialize: function (string) {
        return string.split(',');
      },
    },
  };

  function hashHandler(state) {
    //console.log(state);
    if ('bm' in state) {
      const basemapNames = ['bmapgrau', 'bmaporthofoto30cm'];
      let automatic;
      if (!state.bm || state.bm === 'a') {
        automatic = true;
      } else {
        automatic = false;
      }
      let basemap = getKeyByValue(codes, state.bm); // 'g' stands for bmapgrau, 'o' for orthofoto, 'a' for automatic
      if (basemapNames.indexOf(basemap) === -1 && !automatic) {
        // if something else is in the url, e.g. by mistake when copying
        basemap = 'bmapgrau';
      }
      const layerArray = map.getLayers().getArray();
      for (let i = 0, ii = layerArray.length; i < ii; i++) {
        const thisLayer = layerArray[i];
        const layerName = thisLayer.get('name');
        if (
          layerName === basemap ||
          (automatic && basemapNames.indexOf(layerName) !== -1)
        ) {
          if (automatic) {
            // if automatic, set both basemaps to their according min-max-resolutions
            if (layerName === 'bmapgrau') {
              thisLayer.setMinResolution(bmapGrauMinRes);
              thisLayer.setMaxResolution(bmapGrauMaxRes);
            } else {
              thisLayer.setMinResolution(bmapOrthoMinRes);
              thisLayer.setMaxResolution(bmapOrthoMaxRes);
            }
          } else {
            thisLayer.setMinResolution(bmapOrthoMinRes); // need to set min/max res, because they could be set from layerswitcher: 'auto'
            thisLayer.setMaxResolution(bmapGrauMaxRes);
          }
          thisLayer.setVisible(true);
        } else if (basemapNames.indexOf(layerName) > -1) {
          // set the other basemap invisible
          thisLayer.setVisible(false);
        }
      }
    }
    applyDataDomain(state['d']);
    if ('l' in state) {
      if (state.l.length !== 4) {
        state.l = ['t', 'false', '60', 'kb'];
      }
      const bodenlayerType = getKeyByValue(codes, state.l[0]) || 'bofo_id'; // if undefined
      const sonstigeState = state.l[1] === 'true' ? true : false;
      const opacity = Number(state.l[2]) / 100 || 0.6; // if opacity is NaN, make it 0.6
      const kartierungsbereichType =
        getKeyByValue(codes, state.l[3]) || 'kb_id'; // if undefined;

      layers.kartierungsbereich_mpoly.type = kartierungsbereichType;
      if (kartierungsbereichType === 'erscheinung_jahr') {
        // special case erscheinung_jahr
        layers.sonstigeflaechen_mpoly.visible = false;
        bodenkarte.setVisible(false);
      } else {
        const raster = state.l[0].startsWith('r');
        layers.bodenform_mpoly.type = raster ? undefined : bodenlayerType;
        layers.sonstigeflaechen_mpoly.visible = sonstigeState;
        bodenkarte.setVisible(!raster);
        rasterkarte.setVisible(raster);
      }
      bodenkarte.setOpacity(opacity);
      kartierungsbereich.setOpacity(opacity);
      rasterkarte.setOpacity(opacity);
    }
  }

  update = hashed.register(config, hashHandler);

  function onChangeLayerSync(e) {
    // get the visible basemap
    const layerArray = map.getLayers().getArray();
    const visibleLayers = [];
    let basemap = '';
    for (let i = 0, ii = layerArray.length; i < ii; i++) {
      const layerName = layerArray[i].get('name');
      if (layerName === 'bmapgrau' || layerName === 'bmaporthofoto30cm') {
        if (layerArray[i].getVisible() === true) {
          if (basemap !== '') {
            // if more than one basemaps are visible, it is set to 'automatic'
            basemap = 'a';
            break;
          }
          basemap = codes[layerName]; // g = bmapgrau, o = orthofoto
        }
      }
    }
    visibleLayers.push(
      codes[layers.bodenform_mpoly.type || layers.erosionskarte.type]
    );
    visibleLayers.push(layers.sonstigeflaechen_mpoly.visible);
    visibleLayers.push(Math.floor(bodenkarte.getOpacity() * 100));
    visibleLayers.push(codes[layers.kartierungsbereich_mpoly.type]);

    update({
      bm: basemap,
      l: visibleLayers,
    });
  }

  map.once('rendercomplete', () => {
    const mapLayers = map.getLayers().getArray();
    for (let i = 0, n = mapLayers.length; i < n; i++) {
      mapLayers[i].on('change', onChangeLayerSync);
      mapLayers[i].on('change:visible', onChangeLayerSync);
      mapLayers[i].on('change:opacity', onChangeLayerSync);
    }

    return function unregister() {
      for (let i = 0, n = mapLayers.length; i < n; i++) {
        mapLayers[i].un('change', onChangeLayerSync);
        mapLayers[i].un('change:visible', onChangeLayerSync);
        mapLayers[i].un('change:opacity', onChangeLayerSync);
      }

      hashed.unregister(hashHandler);
    };
  });
}
