/* databeautify functions mainly for popup.js ----------------------------------------------------------------- */

/* profilstelleBeautify             */
/* Generates HTML output for        */
/* Profilstelle PopUp               */
export function profilstelleBeautify(data) {
  let result = '';
  let pdata = null; // Daten aus profile json hier rein
  let hdata = null; // Daten aus horizonte json hier rein

  if (typeof data[0].prof7_id == 'undefined') {
    // Erkennen der beiden JSON files anhand prof7_id
    pdata = data[1];
    hdata = data[0];
  } else {
    pdata = data[0];
    hdata = data[1];
  }

  // 1. Profilstelle...
  result += '<h1>Profilstelle:</h1>';
  result += '<p><span>Kulturart: ' + pdata.nutzung + '</span>';
  result += '; <span>Seehöhe: ' + pdata.seehoehe + 'm</span>';
  result += '; <span>Relief: ' + pdata.reliefart;
  if (pdata.neigung) {
    result += ', ' + pdata.neigung + '';
    if (pdata.ausrichtung) {
      result += ' ' + pdata.ausrichtung;
    }
  }
  result += '</span>';
  result +=
    '; <span>Wasserverhältnisse: ' + pdata.wasserverhaeltnisse + '</span></p>';

  // 2. Profilbeschreibung
  hdata.sort(hzSort);
  result += '<h1>Profilbeschreibung</h1>';
  const hnoplot = [
    'von_cm',
    'bis_cm',
    'horizont',
    'horizontabfolge',
    'prof7_id',
    'hor8_id',
    'entnahmetiefe_cm',
    'humus',
    'kalk',
    'munsell_nummer',
    'ph',
    'sand_prozent',
    'schluff_prozent',
    'ton_prozent',
    'von_cm',
  ];
  const atable = [
    'entnahmetiefe_cm',
    'sand_prozent',
    'schluff_prozent',
    'ton_prozent',
    'humus',
    'kalk',
    'ph',
  ];
  let atablestring = '';
  result += '<table class="profilbeschreibung">';
  for (let h = 0; h < hdata.length; h++) {
    result +=
      '<tr><td>' +
      hdata[h]['horizont'] +
      '</td><td>' +
      hdata[h].von_cm +
      ' - ' +
      hdata[h].bis_cm +
      'cm; ';
    let attemp = '';
    let attest = false;
    attemp += '<tr>';
    for (let a = 0; a < atable.length; a++) {
      if (hdata[h][atable[a]]) {
        attemp += '<td>' + hdata[h][atable[a]] + '</td>';
        attest = true;
      } else {
        attemp += '<td>-</td>';
      }
    }
    attemp += '</tr>';
    if (attest) {
      atablestring += attemp;
    }

    const harray = [];
    if (
      typeof hdata[h]['farbe'] !== 'undefined' &&
      typeof hdata[h]['munsell_nummer'] !== 'undefined'
    ) {
      hdata[h]['farbe'] += ' (' + hdata[h]['munsell_nummer'] + ')';
    }
    for (const hr in hdata[h]) {
      if (hnoplot.indexOf(hr) == -1) {
        if (hdata[h][hr]) {
          harray.push(hdata[h][hr]);
        }
      }
    }
    result += harray.join('; ') + '</td></tr>';
  }
  result += '</table>';

  // 3. Weitere...
  if (pdata.ausgangsmaterial) {
    result += '<h1>Ausgangsmaterial</h1><p>' + pdata.ausgangsmaterial + '</p>';
  }
  if (pdata.bodentyp) {
    result += '<h1>Bodentyp</h1><p>' + pdata.bodentyp + '</p>';
  }
  if (pdata.anmerkung) {
    result += '<h1>Anmerkung</h1><p>' + pdata.anmerkung + '</p>';
  }

  // 4. Analyseergebnisse
  result += '<h1>Analyseergebnisse</h1>';
  result += '<table class="analyse">';
  result +=
    '<tr class="tablehead"><td rowspan="2">Entnahme-<br>tiefe<br>cm</td><td colspan="3">Zusammensetzung des<br>Feinbodens in %</td><td rowspan="2">Humus<br>(Walkley)<br>%</td rowspan="2"><td rowspan="2">Kalk<br>(Scheibler)<br>%</td><td rowspan="2">pH<br>in 0,01 m<br>CaCl<sub>2</sub></td></tr>';
  result +=
    '<tr class="tablehead"><td>2,000 -<br>0,060 mm</td><td>0,060 -<br>0,002 mm</td><td>unter<br>0,002 mm</td></tr>';
  result += atablestring;
  result += '</table>';

  return result;
}

/* bofoBeautify                     */
/* Generates HTML output for        */
/* Bodenformen PopUp                */
export function bofoBeautify(data) {
  let result = '';
  const props = {
    // Properties, Header and boolean for special text processing
    'groesse_der_bodenform': ['Größe der Bodenform', false, ''],
    'lage_vorkommen': ['Lage und Vorkommen', false, ''],
    'bodentyp_ausgangsmaterial': ['Bodentyp', false, ''],
    'wasserverhaeltnisse': ['Wasserverhältnisse', false, ''],
    'horizonte': [
      'Horizonte',
      false,
      '<h4>(jeweils untere Begrenzung in cm)</h4>',
    ],
    'bodenart_grobanteil': ['Bodenart und Grobanteil', true, ''],
    'humusverhaeltnisse': ['Humusverhältnisse', true, ''],
    'kalkgehalt': ['Kalkgehalt', true, ''],
    'bodenreaktion': ['Bodenreaktion', true, ''],
    'erosionsgefahr': ['Erosionsgefahr', false, ''],
    'bearbeitbarkeit': ['Bearbeitbarkeit', false, ''],
    'natuerlicher_bodenwert': ['Natürlicher Bodenwert', false, ''],
    'sonstige_angaben': ['Sonstige Angaben', false, ''],
  };

  for (const p in props) {
    if (typeof data[p] != 'undefined' && data[p]) {
      result +=
        '<h1>' +
        props[p][0] +
        '</h1>' +
        props[p][2] +
        bofoStringProcess(data[p], data.horizonte, props[p][1]);
    }
  }

  return result;
}

/* internal supplemental functions ------------------------------------------------ */

/* bofoStringProcess - Analyzes and rebuilds strings with "horizonte" labels */
function bofoStringProcess(input, horizonte, process) {
  let afterString = '';
  if (input.indexOf('<br>') > -1) {
    afterString = input.substr(input.indexOf('<br>'));
    input = input.substr(0, input.indexOf('<br>'));
  }
  if (!process || typeof horizonte == 'undefined' || !horizonte) {
    if (input.substr(-1) == ';') {
      input = input.substr(0, input.length - 1);
    }
    return '<p>' + input + '</p>';
  }
  //console.log(input, horizonte, process); //eslint-disable-line
  horizonte = horizonte.replace(/[\s|\)]+/gi, ''); // Leerzeichen..
  const horw = horizonte.split(';');
  const hora = [];
  const horcm = {};

  for (let h = 0; h < horw.length; h++) {
    const chor = horw[h].split('(');
    hora.push(chor[0]);
    horcm[chor[0]] = chor[1];
  }

  input = input.replace(/\s+/, ' '); // Evtl. Doppel-Leerzeichen..
  const inpa = input.split(' ');

  let parsed = '';
  let tmpa = [];
  let tmpb = [];

  for (let s = 0; s < inpa.length; s++) {
    //console.log(inpa[s],hora,horcm); //eslint-disable-line

    if (hora.indexOf(inpa[s]) > -1) {
      if (tmpb.length == 0) {
        tmpa.push(inpa[s]);
      } else {
        parsed +=
          '<tr><td>' +
          tmpa.join(',') +
          '</td><td>' +
          tmpb.join(' ') +
          '</td></tr>';
        tmpa = [inpa[s]];
        tmpb = [];
      }
    } else {
      tmpb.push(inpa[s]);
    }
  }
  if (tmpa.length > 0 && tmpb.length > 0) {
    parsed +=
      '<tr><td>' + tmpa.join(',') + '</td><td>' + tmpb.join(' ') + '</td></tr>';
  }
  if (parsed != '') {
    parsed = '<table class="parsedtable">' + parsed + '</table>';
  } else {
    parsed = '<p>' + input + '</p>';
  }
  if (afterString != '') {
    parsed += afterString;
  }
  return parsed;
}

/* sort function for horizonte json */
function hzSort(a, b) {
  if (a.horizontabfolge > b.horizontabfolge) {
    return 1;
  }
  if (a.horizontabfolge < b.horizontabfolge) {
    return -1;
  }
  return 0;
}
